<template>
  <div
      class="hover-item"
      style="cursor: pointer" @click="$emit('selectRecording', rec['Recording File'])"
      :style="{
              background: selectedRec === rec['Recording File'] ? 'var(--selected-item)' : null
            }"
  >
    <v-layout row style="overflow-x: hidden" class="px-2 py-1" align-center>
      <v-flex shrink class="pr-2">
        <simple-audio :src="previewAddress" />
      </v-flex>
      <v-flex xs5 style="overflow-x: hidden" class="py-1">
        <div class="body-1 text-truncate" style="overflow-x: hidden">
          {{ callFrom.main }}
          <div v-if="callFrom.sub" class="caption mt-1" style="color: var(--on-surface-grey);">
            {{callFrom.sub}}
          </div>
        </div>
      </v-flex>
      <v-flex xs5 style="overflow-x: hidden" class="py-1">
        <div class="body-1 text-truncate" style="overflow-x: hidden">
          {{ callTo.main }}
          <div v-if="callTo.sub" class="caption mt-1" style="color: var(--on-surface-grey);">
            {{callTo.sub}}
          </div>
        </div>
      </v-flex>
      
      <v-flex xs2 class="caption font-weight-bold greyType--text " style="white-space: nowrap">
        {{ recordingDuration }}
      </v-flex>
      
      <v-flex xs2 class="caption font-weight-bold greyType--text mr-3" style="white-space: nowrap">
        {{ recordingDate }} {{ recordingTime }}
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
  </div>
</template>

<script>
// "ID": "2024110413344961B9E",
// "Time": "04/11/2024 13:34:49",
// "Call From": "Andisha<2>",
// "Call To": "0860665544",
// "Call Duration": "19",
// "Ring Duration": "2",
// "Talk Duration": "17",
// "Status": "ANSWERED",
// "Reason": "Andisha<2> hangup",
// "Source Trunk": null,
// "Destination Trunk": "FirstNet-PortaOne",
// "Communication Type": "Outbound",
// "DID": null,
// "DOD": "0310015503",
// "Caller IP Address": "102.218.55.117:57922",
// "PIN Code": null,
// "Recording File":

import {codeBaseMixin} from "../../../../codeBaseMixin";
import SimpleAudio from "../../../../components/General/SimpleAudio";
import {mapGetters} from "vuex";
import {serverAddress} from "@/axiosRequest";
import {token} from "@/tokenManSite";

export default {
  mixins: [codeBaseMixin],
  components: {
    SimpleAudio,
  },
  props: {
    ext: [Number, String],
    rec: Object,
    selectedRec: String,
    showFrom: {default: true, type: Boolean},
    showTo: {default: true, type: Boolean}
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters("userInfo", ["userInfo"]),
    
    
    previewAddress() {
      return `${serverAddress}/yeastarPSeries/recording/preview/${this.rec['Recording File']}/${token()}`
    },
    
    recordingDuration() {
      let callTime = +this.rec['Call Duration']
      
      const minutes = Math.floor(callTime / 60)
      const seconds = callTime % 60
      
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
    
    callFrom() {
      if (this.rec['Call From'].match(/<\d+>/g))
        return {
          main: this.rec['Call From'].replace(/<\d+>/g, ''),
          sub: this.rec['Call From'].replace(/.+[^<]</g, '').replace('>', '')
        }
      return {
        main: this.rec['Call From']
      }
      
    },
    
    callTo() {
      if (this.rec['Call To'].match(/<\d+>/g))
        return {
          main: this.rec['Call To'].replace(/<\d+>/g, ''),
          sub: this.rec['Call To'].replace(/.+[^<]</g, '').replace('>', '')
        }
      return {
        main: this.rec['Call To']
      }
      
    },
    
    recordingTime() {
      return this.getSqlTime(this.rec.Time)
    },
    recordingDate() {
      return this.getShortDateLongYear(this.rec.Time)
    },
    toDescription() {
      try {
        return this.rec['Call To']
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return '-'
      }
    },
    toNumber() {
      try {
        return this.rec['Call To']
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return '-'
      }
    }
  }
}
</script>

<style scoped>

</style>
