<template>
    <div class="pa-1">

        <!--Rename Field-->
        <div :class="topicClass">
            <div :class="headingClass">
                Field Description:
            </div>
            <div :class="itemClass">

                <v-layout row align-center>
                    <v-flex xs12>
                        <v-text-field
                                label="Field Description"
                                single-line
                                color="primary"
                                hide-details
                                style="width: 100%"
                                class="pa-0 ma-0"
                                ref="fieldDescription"
                                v-model="editedDescription"
                                @input="saveMetaChangesTimeout"
                                @keydown.enter="saveMetaChanges"
                        ></v-text-field>
                    </v-flex>
                    <v-flex shrink class="px-1" v-if="editedDescription !== initDescription">
                        <v-tooltip top>
                            <v-btn
                                    icon
                                    flat
                                    small
                                    color="primary"
                                    slot="activator"
                                    class="pa-0 ma-0"
                                    @click="editedDescription = initDescription; saveMetaChanges()"
                            >
                                <v-icon>undo</v-icon>
                            </v-btn>
                            <span>Undo</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
        </div>
        <v-layout row>
            <v-flex xs6>
                <!--Visibility-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Visible:
                    </div>
                    <!--:label="visible ? 'Shown' : 'Hidden'"-->
                    <div :class="itemClass">
                        <v-switch
                                v-model="visible"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
            <v-flex xs6 v-if="![12, 20].includes(field.fieldType)">
                <!--Required-->
                <div :class="topicClass" style="overflow-y: hidden">
                    <div :class="headingClass">
                        Required:
                    </div>

                    <div :class="itemClass">
                        <!--:label="required ? 'Required' : 'Not Required'"-->
                        <v-switch
                                v-model="required"
                                hide-details
                                color="secondary"
                                class="pa-0 ma-0"
                        ></v-switch>
                    </div>
                </div>
            </v-flex>
        </v-layout>
        <!--Field Links-->
        <div :class="topicClass" v-if="[1,2,3,4,5,6,7,13].includes(field.fieldType)">
            <div :class="headingClass">
                Dataset Link:
            </div>
            <div :class="itemClass">
                <app-field-set-field-selector
                        v-model="field.linkLookup"
                        :fields="fields"
                        :field-sets="fieldSets"
                        :field-type-filter="[field.fieldType != 7 ? field.fieldType : 6]"
                        @input="saveMetaChanges"
                />
            </div>
        </div>
        <!--Field Type 1-->
        <div :class="topicClass" v-if="[1].includes(field.fieldType)">
            <div :class="headingClass">
                Update Task Title:
            </div>
            <div :class="itemClass">
                <v-switch
                        v-model="booleanLookup"
                        label="Update Task Title"
                        hide-details
                        color="primary"
                        class="pa-0 ma-0"
                        @change="saveLookupChanges"
                ></v-switch>
            </div>
        </div>
        <!--Field Type 2-->
        <div :class="topicClass" v-if="[2].includes(field.fieldType)">
            <field-type2-configure
                    :user-info="userInfo"
                    :select-lists="selectLists"
                    v-model="fullLookup"
            />
        </div>
        <!--Field Type 5-->
        <div :class="topicClass" v-if="[5].includes(field.fieldType)">
            <div :class="headingClass">
                List Options:
            </div>
            <div :class="itemClass">
                <app-field-type6-configure
                        :user-info="userInfo"
                        :select-lists="selectLists"
                        v-model="fullLookup"
                />
            </div>
        </div>
        <!--Field Type 7-->
        <div :class="topicClass" v-if="[7].includes(field.fieldType)">
            <div :class="headingClass">
                Restricted User Groups:
            </div>
            <div :class="itemClass">
                <app-workflow-manager-group-selector
                        :user-groups="userGroups"
                        btn-label="No Restricted User Groups"
                        ac-label="No Restricted User Groups"
                        v-model="parsedLookup"
                />
            </div>
        </div>
        <!--Field Type 8-->
        <div :class="topicClass" v-if="[8].includes(field.fieldType)">
            <div :class="headingClass">
                Mail Template Configuration:
            </div>
            <div :class="itemClass">
                <app-field-type8-configure
                        :user-info="userInfo"
                        v-model="field.lookup"
                        :fields="fields"
                        :steps="steps"
                        :fieldSets="fieldSets"
                        :signatures="signatures"
                        :show-template-editor-fn="showTemplateEditorFn"
                        :field="field"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>
        <!--Field Type 9-->
        <template v-if="[9].includes(field.fieldType)">

            <!--Select Folder-->
            <v-layout row>
                <v-flex xs6>
                    <!--Hidden If Populated-->
                    <div :class="topicClass" style="overflow-y: hidden">
                        <div :class="headingClass">
                            Create Only:
                        </div>
                        <div :class="itemClass">
                            <v-switch
                                    v-model="field.config.createOnly"
                                    hide-details
                                    color="secondary"
                                    class="pa-0 ma-0"
                                    @change="(event) => {
                                        if (event)
                                            field.config.selectOnly = !event
                                        saveLookupChanges();
                                    }"
                            ></v-switch>
                        </div>
                    </div>
                </v-flex>
                <v-flex xs6>
                    <!--Select Only-->
                    <div :class="topicClass" style="overflow-y: hidden">
                        <div :class="headingClass">
                            Select Only:
                        </div>

                        <div :class="itemClass">
                            <!--:label="required ? 'Required' : 'Not Required'"-->
                            <v-switch
                                    v-model="field.config.selectOnly"
                                    hide-details
                                    color="secondary"
                                    class="pa-0 ma-0"
                                    @change="(event) => {
                                        saveLookupChanges();
                                        if (event)
                                            field.config.createOnly = !event
                                    }"
                            ></v-switch>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout row>
                <v-flex xs6>
                    <!--Hidden If Populated-->
                    <div :class="topicClass" style="overflow-y: hidden">
                        <div :class="headingClass">
                            Hidden If Populated:
                        </div>
                        <div :class="itemClass">
                            <v-switch
                                    v-model="field.config.hiddenIfPopulated"
                                    hide-details
                                    color="secondary"
                                    class="pa-0 ma-0"
                                    @change="saveLookupChanges()"
                            ></v-switch>
                        </div>
                    </div>
                </v-flex>
              <v-flex xs6>
                <!--Hidden If Populated-->
                <div :class="topicClass" style="overflow-y: hidden">
                  <div :class="headingClass">
                    Block Auto Populate:
                  </div>
                  <div :class="itemClass">
                    <v-switch
                        v-model="blockAutoPopulate"
                        hide-details
                        color="secondary"
                        class="pa-0 ma-0"
                        @change="saveLookupChanges()"
                    ></v-switch>
                  </div>
                </div>
              </v-flex>

            </v-layout>

            <!--Select Folder-->
            <div :class="topicClass" v-if="typeof field.config.folder != 'undefined'">
                <div :class="headingClass">
                    Dataset Folder:
                </div>
                <div :class="itemClass">
                    <app-workflow-manager-group-selector
                            v-model="field.config.folder"
                            :user-groups="fields.filter(fField => fField.fieldType === 21)"
                            ac-label="Task Folder (Select to change)"
                            btn-label="Task Folder (Select to change)"
                            lbl-label="Folder Select Field"
                            @input="saveLookupChanges()"
                            :multiple="false"
                    />
                </div>
            </div>

            <!--Select Dataset-->
            <div :class="topicClass">
                <div :class="headingClass">
                    Dataset Field:
                </div>
                <div :class="itemClass">
                    <app-field-set-selector
                            v-model="field.config.dataset"
                            :user-info="userInfo"
                            :field="field"
                            :fields="fields"
                            :field-sets="fieldSets"
                            :field-type-filter="[field.fieldType]"
                            @input="saveLookupChanges()"
                    />
                </div>
            </div>
<!--            &lt;!&ndash;Hide Dataset&ndash;&gt;-->
<!--            <div :class="topicClass">-->
<!--                <div :class="headingClass">-->
<!--                    Dataset Select Behaviour:-->
<!--                </div>-->
<!--                <div :class="itemClass">-->
<!--                    <v-autocomplete-->
<!--                            dense-->
<!--                            v-model="integerLookupType"-->
<!--                            :items="[{id: 0, description: 'Regular'},{id: 1, description: 'Hidden if populated'},{id: 2, description: 'Select only (no add dataset button)'},{id: 3, description: 'Hidden if populated and select only'}]"-->
<!--                            label="Dataset Behaviour"-->
<!--                            hide-details-->
<!--                            single-line-->
<!--                            color="primary"-->
<!--                            item-text="description"-->
<!--                            item-value="id"-->
<!--                            class="pa-0 ma-0"-->
<!--                            @input="saveLookupChanges"-->
<!--                    ></v-autocomplete>-->
<!--&lt;!&ndash;                    <v-switch&ndash;&gt;-->
<!--&lt;!&ndash;                            v-model="booleanLookupType"&ndash;&gt;-->
<!--&lt;!&ndash;                            label="Hidden if Populated"&ndash;&gt;-->
<!--&lt;!&ndash;                            hide-details&ndash;&gt;-->
<!--&lt;!&ndash;                            color="primary"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="pa-0 ma-0"&ndash;&gt;-->
<!--&lt;!&ndash;                            @change="saveLookupChanges"&ndash;&gt;-->
<!--&lt;!&ndash;                    ></v-switch>&ndash;&gt;-->
<!--                </div>-->
<!--            </div>-->
            <!--Select Fields-->
            <div :class="topicClass">
                <div :class="headingClass">
                    Dataset Fields:
                </div>
                <div :class="itemClass">
                    <app-field-type9-configure
                            :user-info="userInfo"
                            :field="field"
                            :fields="fields"
                            :field-sets="fieldSets"
                            :steps="steps"
                    />
                </div>
            </div>
        </template>
        <!--Field Type 10-->
        <div :class="topicClass" v-if="[10].includes(field.fieldType)">
            <div :class="headingClass">
                Split Task Configuration:
            </div>
            <div :class="itemClass">
                <!--@input="saveLookupChanges()"-->
                <app-field-type10-configure
                        :user-info="userInfo"
                        v-model="field.lookup"
                        :fields="fields"
                        :split-task-workflows="splitTaskWorkflows"
                        :steps="steps"
                        :folder-groups="folderGroups"
                        @input="saveLookupChanges()"
                        :local-data="localData"
                        :show-sync="showSync"
                        :get-task-header="getTaskHeader"
                />
            </div>
        </div>
        <!--Field Type 11-->
        <div :class="topicClass" v-if="[11].includes(field.fieldType)">
            <div :class="headingClass">
                Checkbox List:
            </div>
            <div :class="itemClass">
                <app-field-type11-configure
                        v-model="field.lookup"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>
        <!--Field Type 12-->
        <div :class="topicClass" v-if="[12].includes(field.fieldType)">
            <div :class="headingClass">
                Force Close Field:
            </div>
            <div :class="itemClass">
                <v-switch
                        v-model="forceCloseLookup"
                        :label="forceCloseLookup ? 'Force Close Enabled' : 'Force Close'"
                        hide-details
                        color="secondary"
                        class="pa-0 ma-0"
                ></v-switch>
            </div>
        </div>
        <!--Field Type 17-->
        <div :class="topicClass" v-if="[17].includes(field.fieldType)">
            <div :class="headingClass">
                Configure SMS Field:
            </div>
            <div :class="itemClass">
                <app-field-type17-configure
                        :user-info="userInfo"
                        v-model="field.lookup"
                        :local-data="localData"
                        :fields="fields"
                        :steps="steps"
                        :fieldSets="fieldSets"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>
        <!--Field Type 19-->
        <div :class="topicClass" v-if="[19].includes(field.fieldType)">
            <div :class="headingClass">
                Configure Document Template:
            </div>
            <div :class="itemClass">
                <document-template
                        v-model="field.lookup"
                        :user-info="userInfo"
                        :fields="fields"
                        :datasets="fieldSets"
                        :steps="steps"
                        :select-lists="selectLists"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>

        <!--Field Type 20-->
        <div :class="topicClass" v-if="[20].includes(field.fieldType)">
            <div :class="headingClass">
                Configure Information Field:
            </div>
            <div :class="itemClass">
                <information-field-editor
                        :field="field"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>
<!--Field Type 21-->
        <field-type21-configure
                v-if="[21].includes(field.fieldType)"
                :folder-groups="folderGroups"
                :field="field"
                @input="saveLookupChanges()"
        />
<!--Field Type 22-->
        <div :class="topicClass" v-if="[22].includes(field.fieldType)">
            <div :class="headingClass">
                Configure Eval Field:
            </div>
            <div :class="itemClass">
                <app-field-type22-configure
                        :user-info="userInfo"
                        v-model="field.lookup"
                        :local-data="localData"
                        :fields="fields"
                        :steps="steps"
                        :fieldSets="fieldSets"
                        @input="saveLookupChanges()"
                />
            </div>
        </div>

<!--Field Type 23-->
      <div :class="topicClass" v-if="[23].includes(field.fieldType)">
        <div :class="headingClass">
          Configure MS Word Template:
        </div>
        <div :class="itemClass">
          <div class="pt-1">
            <v-btn small flat color="secondary" class="ma-0" @click="showWordTemplate = !showWordTemplate">Configure</v-btn>
          </div>
        </div>
        <word-template-modify
          :shown.sync="showWordTemplate"
          :field-id="field.id"
          v-if="showWordTemplate"
        />
      </div>
        <!--Field Shown To-->
        <div :class="topicClass">
            <div :class="headingClass">
                Field Shown To:
            </div>
            <div :class="itemClass">
                <app-workflow-manager-group-selector
                        :user-groups="userGroups"
                        btn-label="Shown to All"
                        ac-label="Shown to All"
                        v-model="securityShownTo"
                />
            </div>
        </div>
        <!--Field Editable By-->
        <div :class="topicClass">
            <div :class="headingClass">
                Field Editable By:
            </div>
            <div :class="itemClass">
                <app-workflow-manager-group-selector
                        :user-groups="userGroups"
                        btn-label="Editable by All"
                        ac-label="Editable by All"
                        v-model="securityEditableBy"
                />
            </div>
        </div>

        <!--<div class="caption">-->
        <!--{{field}}-->
        <!--</div>-->


    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../../codeBaseMixin";
    import FieldSetFieldSelector from './FieldSetFieldSelector';
    import FieldSetSelector from './FieldSetSelector';
    import WorkflowManagerGroupSelector from "./WorkflowManagerGroupSelector";
    import FieldType6Configure from "./FieldTypeConfigureFields/FieldType6Configure";
    import FieldType8Configure from "./FieldTypeConfigureFields/FieldType8Configure";
    import FieldType9Configure from "./FieldTypeConfigureFields/FieldType9Configure";
    import FieldType10Configure from "./FieldTypeConfigureFields/FieldType10Configure";
    import FieldType11Configure from "./FieldTypeConfigureFields/FieldType11Configure";
    import FieldType17Configure from "./FieldTypeConfigureFields/FieldType17Components/FieldType17Configure";
    import FieldType22Configure from "./FieldTypeConfigureFields/FieldType22Components/FieldType22Configure";
    import DocumentTemplate from "../../DocumentTemplate/DocumentTemplate";
    import WordTemplateModify from "../../WordTemplate/WordTemplateModify.vue";
    import FieldType2Configure from "./FieldTypeConfigureFields/FieldType2Configure";
    import InformationFieldEditor
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/InformationFieldEditor/InformationFieldEditor";
    import FieldType21Configure
        from "@/Admin/WorkflowEditor/Components/WorkflowManager/Workflow/Components/FieldTypeConfigureFields/FieldType21Configure";

    export default {
        components: {
            FieldType21Configure,
            InformationFieldEditor,
            FieldType2Configure,
            DocumentTemplate,
            appFieldType17Configure: FieldType17Configure,
            appFieldType6Configure: FieldType6Configure,
            appFieldType8Configure: FieldType8Configure,
            appFieldType9Configure: FieldType9Configure,
            appFieldType10Configure: FieldType10Configure,
            appFieldType11Configure: FieldType11Configure,
            appWorkflowManagerGroupSelector: WorkflowManagerGroupSelector,
            appFieldSetFieldSelector: FieldSetFieldSelector,
            appFieldSetSelector: FieldSetSelector,
            appFieldType22Configure: FieldType22Configure,
          WordTemplateModify
        },

        mixins: [codeBaseMixin],
        props: {
            shown: Boolean,
            userInfo: Object,
            field: Object,
            step: Object,
            fields: Array,
            rules: Array,
            fieldSets: Array,
            userGroups: Array,
            selectLists: Array,
            steps: Array,
            splitTaskWorkflows: Array,
            folderGroups: Array,
            signatures: Array,
            showTemplateEditorFn: Function,
            localData: Object,
            showSync: Boolean,
            getTaskHeader: Function,
            saveMetaChangesTimer: null,
        },
        data() {
            return {
                headingClass: 'caption greyType--text font-weight-bold',
                topicClass: 'pa-1',
                itemClass: 'pl-2',
                editedDescription: null,
                savingChanges: false,
                initDescription: null,
                showWordTemplate: false
            }
        },
        watch: {
            shown: {
                immediate: true,
                handler(val) {
                    if (val) {

                        if (!this.field.config) {
                            this.$set(this.field, 'config', {})
                        }

                        switch (this.field.fieldType) {
                            case 9: {
                                if (typeof this.field.config.folder === 'undefined')
                                    this.$set(this.field.config, 'folder', null)
                                if (typeof this.field.config.dataset === 'undefined')
                                    this.$set(this.field.config, 'dataset', null)
                                if (typeof this.field.config.hiddenIfPopulated === 'undefined')
                                    this.$set(this.field.config, 'hiddenIfPopulated', false)
                                if (typeof this.field.config.selectOnly === 'undefined')
                                    this.$set(this.field.config, 'selectOnly', false)
                                if (typeof this.field.config.createOnly === 'undefined')
                                    this.$set(this.field.config, 'createOnly', false)
                            }
                        }


                        // Description Settings
                        this.editedDescription = this.field.description;
                        this.initDescription = this.field.description;

                        //Set The Focus to the description
                        setTimeout(() => {this.$refs.fieldDescription.focus();}, 200)

                        if (val && [12,20].includes(this.field.fieldType)) {
                            this.field.required = 0;
                        }
                    }
                }
            }
        },

        methods: {
            clearMetaTimer() {
                try {
                    clearTimeout(this.saveMetaChangesTimer)
                } catch (e) {
                    // eslint-disable-next-line
                    console.log(e)
                }

                this.saveMetaChangesTimer = null;
            },

            saveMetaChangesTimeout() {
                this.clearMetaTimer();

                this.saveMetaChangesTimer = setTimeout(() => {
                    this.saveMetaChanges();
                }, 1000);
            },

            saveMetaChanges() {
                this.clearMetaTimer();

                if (this.editedDescription == null || this.editedDescription == '') {
                    this.showSnack('Error', 'Field Description Required', 'Close', 'red');
                    return;
                }

                let newVal = {};
                if (this.securityEditableBy.length > 0) {
                    newVal.editableBy = this.securityEditableBy;
                }
                if (this.securityShownTo.length > 0) {
                    newVal.shownTo = this.securityShownTo;
                }

                this.savingChanges = true;
                this.fetch({method: 'PATCH', url:`/workflowManager/field/meta/${this.userInfo.entityID}/${this.field.id}`, body: JSON.stringify({
                        description: this.editedDescription,
                        visible: this.field.visible,
                        required: this.field.required,
                        securityLookup: newVal,
                        linkLookup: this.field.linkLookup
                    })})
                    .then((data) => {
                        this.savingChanges = false;
                        this.field.description = this.editedDescription;

                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');

                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })
            },
            saveLookupChanges() {
                console.log("RAN RAN", this.field.lookup)
                this.savingChanges = true;
                this.fetch({method: 'PATCH', url:`/workflowManager/field/lookup/${this.userInfo.entityID}/${this.field.id}`, body: JSON.stringify({
                        fieldType: this.field.fieldType,
                        lookup: this.field.fieldType !== 22 ? (typeof this.field.lookup == 'object' && this.field.lookup != null ? JSON.stringify(this.field.lookup) : this.field.lookup) : this.field.lookup,
                        lookup_type: this.field.lookup_type,
                        config: this.field.config,
                    })})
                    .then((data) => {
                        this.savingChanges = false;
                        this.showSnack('Info', 'Changes Saved', 'Close', 'primary');
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.savingChanges = false;
                    })
            }
        },
        computed: {
          
          blockAutoPopulate: {
            get() {
              return this.field?.config?.blockAutoPopulate || false
            },
            set(val) {
              this.$set(this.field.config, 'blockAutoPopulate', val)
            }
          },
          
            booleanLookup: {
                get() {
                    try {
                        return this.intToBool(parseInt(this.field.lookup))
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup = `${this.boolToInt(val)}`
                }
            },

            integerLookupType: {
                get() {
                    try {
                        return parseInt(this.field.lookup_type)
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup_type = parseInt(val)
                }
            },

            booleanLookupType: {
                get() {
                    try {
                        return this.intToBool(parseInt(this.field.lookup_type))
                    } catch (e) {
                        return false
                    }
                },
                set(val) {
                    this.field.lookup_type = `${this.boolToInt(val)}`
                }
            },

            parsedLookup: {
                get: function () {
                    return this.isJson(this.field.lookup)
                },
                set: function (newValue) {
                    this.field.lookup = JSON.stringify(newValue);
                    this.saveLookupChanges();
                }
            },
            fullLookup: {
                get: function () {
                    return {
                        lookup_type: this.field.lookup_type,
                        lookup: this.field.lookup
                    }
                },
                set: function (newValue) {
                    this.field.lookup_type = newValue != null && typeof newValue.lookup_type != "undefined" ? newValue.lookup_type : null;
                    this.field.lookup = newValue != null && typeof newValue.lookup != "undefined" ? newValue.lookup : null;
                    this.saveLookupChanges();
                }
            },
            forceCloseLookup: {
                get: function () {
                    return this.intToBool(this.parsedLookup)
                },
                set: function (newValue) {
                    this.field.lookup = `${this.boolToInt(newValue)}`;
                    this.saveLookupChanges();
                }
            },
            visible: {
                get: function () {
                    return this.intToBool(this.field.visible)
                },
                set: function (newValue) {
                    this.field.visible = this.boolToInt(newValue)
                    this.saveMetaChanges();
                }
            },
            required: {
                get: function () {
                    return this.intToBool(this.field.required)
                },
                set: function (newValue) {
                    this.field.required = this.boolToInt(newValue)
                    this.saveMetaChanges();
                }
            },
            securityShownTo: {
                // getter
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.shownTo != "undefined" ? this.field.securityLookup.shownTo : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.shownTo = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                    this.saveMetaChanges();
                }
            },
            securityEditableBy: {
                get: function () {
                    return this.field.securityLookup != null && typeof this.field.securityLookup.editableBy != "undefined" ? this.field.securityLookup.editableBy : []
                },
                set: function (newValue) {
                    if (this.field.securityLookup == null) {this.field.securityLookup = {}}
                    this.field.securityLookup.editableBy = newValue;
                    this.field.securityLookup = this.field.securityLookup != null ? JSON.parse(JSON.stringify(this.field.securityLookup)) : null;
                    this.saveMetaChanges();
                }
            },
        }
    }
</script>

<style scoped>

</style>
