<template>
  
  <div class="c-d-flex fill-height c-flex-column chat-back" :style="{
    'background-image' : $store.state.darkTheme ? 'url(\'/chatDark.svg\')' : 'url(\'chatBack.svg\')'
  }">
    
    <div v-if="startingUp" class="c-d-flex c-align-center c-justify-center c-flex-grow-1">
      <v-progress-circular
          :size="40"
          :width="5"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    
    <template v-else>
      <slot name="header" :contact=contact :lastSeen="contactLastSeen" :startingUp="startingUp"></slot>
      <div style="position: relative;" >
        <transition
            mode="out-in"
            enter-active-class="fade-in-top"
            leave-active-class="fade-out-top"
        >
          <div key="selected" v-if="selectedMessageIdsSize" class="c-d-flex c-align-center py-0 my-0 pl-3" style="position: absolute; top: 0; left: 0; width: 100%; background: rgba(var(--primary-rgb), 0.7); z-index: 1; backdrop-filter: blur(3px);">
            <v-icon @click="selectedMessageIdsSize = 0; selectedMessageIds.clear()" medium color="white" left>check_box_outline_blank</v-icon>
            <div class="body-2 c-flex-grow-1 white--text px-2 subheading py-2">
              {{selectedMessageIdsSize}} Message{{selectedMessageIdsSize > 1 ? 's' : ''}} selected
            </div>
          </div>
          
          <div key="linking" v-else-if="linkingMessages" class="c-d-flex c-align-center py-0 my-0 pl-3" style="position: absolute; top: 0; left: 0; width: 100%; background: rgba(var(--primary-rgb), 0.7); z-index: 1; backdrop-filter: blur(3px);">
            <v-progress-circular
                :size="18"
                :width="2"
                color="white"
                indeterminate
                class="mr-3"
            ></v-progress-circular>
            <div class="body-2 c-flex-grow-1 white--text px-2 subheading py-2">
              linking messages
            </div>
          </div>
        </transition>
      </div>
      
      
      <div style="position: relative">
        <div v-if="loadingMessages" style="position: absolute; left: 0; width: 100%; top: 0">
          <v-progress-linear class="py-0 my-0" :indeterminate="true" color="secondary"></v-progress-linear>
        </div>
      </div>
      
      <div class="c-flex-grow-1 of-y">
        <virtual-list
            v-show="!!messages.length"
            class="fill-height of-y"
            ref="vsl"
            :data-key="'id'"
            :data-sources="messages"
            :data-component="AppChatMessage"
            :estimate-size="100"
            :item-class="'stream-item'"
            :item-class-add="addItemClass"
            :extra-props="{
              selectedMessageIds,
              handleContextMenu,
              selectedMessageIdsSize,
              handleMessageSelect,
              handleDragStart,
              handleViewTasks,
              allowAttachmentDrag: false,
              showLinkedTasks: true,
              visibleHiddenMessages: shownDeletedMessages
            }"
            @resized="onItemRendered"
            @totop="onTotop"
            @scroll="e => vslScrolled(e)"
        >
        </virtual-list>
        
        <v-menu
            :v-if="showMessageContextMenu && contextMenuMessage"
            :absolute="true"
            v-model="showMessageContextMenu"
            :position-x="messageContextMenuPos.x"
            :position-y="messageContextMenuPos.y"
            offset-y
        >
          <template v-slot:activator="{ on }">
            <div></div>
          </template>
          
          <v-card class="page-back">
            <v-list>
              
              <template v-if="contextMenuMessage?.removed">
                
                <v-list-tile v-if="!shownDeletedMessages.includes(contextMenuMessage.id)" @click="shownDeletedMessages.push(contextMenuMessage.id)">
                  <v-list-tile-avatar>
                    <v-icon class="pr-2">visibility</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Show Message</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-tile v-else @click="shownDeletedMessages.splice(shownDeletedMessages.indexOf(contextMenuMessage.id), 1)">
                  <v-list-tile-avatar>
                    <v-icon class="pr-2">visibility_off</v-icon>
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title>Hide Message</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
              <v-list-tile v-else @click="deleteContextMessage">
                <v-list-tile-avatar>
                  <v-icon class="pr-2">delete</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>Delete Message</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
              
              <v-list-tile @click="() => handleMessageSelect(contextMenuMessage)">
                <v-list-tile-avatar>
                  <v-icon>{{ contextMenuMessage && selectedMessageIds.has(contextMenuMessage.id) ? 'check_box_outline_blank' : 'check_box' }}</v-icon>
                </v-list-tile-avatar>
                <v-list-tile-content>
                  <v-list-tile-title>{{ contextMenuMessage && selectedMessageIds.has(contextMenuMessage.id) ? 'Deselect' : 'Select'  }} Message</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-card>
          
        </v-menu>
        
      </div>
      
      <div>
        <v-divider></v-divider>
      </div>
      
      
      <div class="c-d-flex c-align-center" style="position: relative">
        
        <div style="position: absolute; top: 0; right: 0; transform: translateY(-100%)" class="pa-3">
          <transition
              enter-active-class="scale-in-center"
              leave-active-class="scale-out-center"
              mode="out-in"
          >
            
            <v-btn
                v-if="showScrollToBottom"
                color="secondary"
                icon
                class="pa-0 ma-0"
                @click="$refs.vsl.scrollToBottom()"
            >
              <v-icon>keyboard_double_arrow_down</v-icon>
            </v-btn>
            
            
            <!--            <v-btn-->
            <!--                v-if="showScrollToBottom"-->
            <!--                icon=""-->
            <!--                color="secondary"-->
            <!--                density="comfortable"-->
            <!--                @click="vsl.scrollToBottom()"-->
            <!--            ></v-btn>-->
          </transition>
        </div>
        
        
        <app-combo-compose-message
            :chat-key="chat.chatKey"
            @messageSent="loadNewMessages()"
        />
      </div>
    
      
      
      
    </template>
    
    
    <v-menu
        :v-if="linkedTaskIdsView && linkedTaskIdsView.length"
        :absolute="true"
        :value="linkedTaskIdsView && linkedTaskIdsView.length"
        @input="e => !e ? linkedTaskIdsView = null : null"
        :position-x="linkedTaskIdsMenuPos.x"
        :position-y="linkedTaskIdsMenuPos.y"
        offset-y
        :max-width="500"
    >
      <template v-slot:activator="{ on }">
        <div></div>
      </template>
      
      <v-card style="max-height: 300px;" class="of-y">
        <div v-for="taskId in linkedTaskIdsView">
          <task-header-with-loader :task-id="taskId" @taskSelected="taskSelected"/>
        </div>
      </v-card>
    </v-menu>
    
  </div>
</template>

<script>
import {
  Chat,
  ChatMessage,
  markMessagesAsRead, rtcmRelay, startChatSubscription,
  startLastSeenSubscription, stopChatSubscription,
  stopLastSeenSubscription
} from "@/chat/chatFunctions";
import {newReq} from "@/axiosRequest";
import {isSameDay, isToday, isYesterday, differenceInDays, format} from "date-fns";
import VirtualList from "vue-virtual-scroll-list"
import AppChatMessage from "@/chat/AppChatMessage.vue";
import {mapGetters} from "vuex";
import chat from "@/Store/chat/chat";
import EmojiPicker from '@/components/General/EmojiPicker/EmojiPicker.vue'
import AppContactInformationDlg from "@/contacts/AppContactInformationDlg.vue";
import TaskHeaderWithLoader from "@/components/General/TaskHeaderWithLoader.vue";
import {eventBus} from "@/main";
import AppComboComposeMessage from "@/Tasks/TaskView V2/components/Chat Message/AppComboComposeMessage.vue";

export default {
  components: {
    AppComboComposeMessage,
    TaskHeaderWithLoader,
    AppContactInformationDlg,
    VirtualList,
    AppChatMessage,
    EmojiPicker,
  },
  props: {
    chat: Chat,
  },
  data() {
    return {
      console,
      startingUp: true,
      messages: [],
      messageStr: '',
      contact: null,
      sendingMessage: false,
      loadingMessages: true,
      AppChatMessage,
      overflow: false,
      showScrollToBottom: false,
      onResume: null,
      showEmojiMenu: false,
      contactLastSeen: null,
      
      selectedMessageIds: new Set(),
      selectedMessageIdsSize: 0,
      showMessageContextMenu: false,
      messageContextMenuPos: {x: 0, y: 0},
      contextMenuMessage: null,
      
      linkingMessages: false,
      
      linkedTaskIdsView: null,
      linkedTaskIdsMenuPos: {x: 0, y: 0},
      
      shownDeletedMessages: [],
      isDeletingContextMessage: false
    }
  },
  mounted() {
    // TODO AFL Load Messages Here
    rtcmRelay.on('lastSeen', this.handleLastSeen)
    rtcmRelay.on('chatMessagesLink', this.handleLinkMessages)
    rtcmRelay.on('chatEvent', this.handleChatEvent)
    rtcmRelay.on('messageDeleted', this.handleChatMessageDeleted)
  },
  beforeDestroy() {
    rtcmRelay.off('lastSeen', this.handleLastSeen)
    rtcmRelay.off('chatMessagesLink', this.handleLinkMessages)
    rtcmRelay.off('chatEvent', this.handleChatEvent)
    rtcmRelay.off('messageDeleted', this.handleChatMessageDeleted)
    
    if (this.chat.fkRefContactId)
      stopLastSeenSubscription(this.chat.fkRefContactId)
    if (this.chat.chatKey)
      stopChatSubscription(this.chat.chatKey)
  },
  watch: {
    chat: {
      immediate: true,
      handler(val) {
        this.init()
      }
    },
    "windowFocused": {
      handler(val) {
        if (val && this.$store.state.curView === 'chat' && this.onResume) {
          this.onResume()
          this.onResume = null
        }
      }
    },
    
    "$store.state.curView": {
      handler(val) {
        if (this.$store.state.chat.windowFocused && val === 'chat' && this.onResume) {
          this.onResume()
          this.onResume = null
        }
      }
    },
    
    "chat.fkRefContactId": {
      immediate: true,
      handler(newFkRefContactId, oldFkRefContactId) {
        this.contactLastSeen = null;
        
        if (newFkRefContactId)
          startLastSeenSubscription(this.chat.fkRefContactId)
        
        if (oldFkRefContactId)
          stopLastSeenSubscription(oldFkRefContactId)
      }
    },
    
    "chat.chatKey": {
      immediate: true,
      handler(newChatKey, oldChatKey) {
        if (newChatKey)
          startChatSubscription(newChatKey)
        
        if (oldChatKey)
          stopChatSubscription(oldChatKey)
      }
    },
    
    
    "chat.fkMessageId": {
      async handler(newVal) {
        if (this.messages?.at(-1)?.id === newVal)
          return
        
        if (newVal) {
          // Get All New Messages
          await this.loadNewMessages()
          
          const vm = this
          
          this.onResume = () => {
            vm.chat.unread = 0;
            markMessagesAsRead(vm.chat.chatKey)
          }
          
          if (this.$store.state.chat.windowFocused && this.$store.state.curView === 'chat') {
            this.onResume()
            this.onResume = null
          }
        }
      }
    }
  },
  methods: {
    async handleChatMessageDeleted(payload) {
      if (payload?.chatKey === this.chat.chatKey) {
        const deletedMessage = this.messages.find(v => v.id === payload.messageId)
        if (deletedMessage) {
          this.$set(deletedMessage, "removedByContact", payload.detail.removedByContact || null)
          this.$set(deletedMessage, "removedByUser", payload.detail.removedByUser || null)
          this.$set(deletedMessage, "removed", payload.detail.removed || null)
          this.$set(deletedMessage, "removedName", payload.detail.removedName || null)
          this.$set(deletedMessage, "removedAt", payload.detail.removedAt || null)
        }
      }
      // if (chat.fkMessageId > this.lastChatMessageId) {
      //   await this.chatLoadNewMessages()
      // }
    },
    
    async deleteContextMessage() {
      try {
        this.isDeletingContextMessage = true
        this.$newReq('POST', `chat/deleteMessage`, {messageId: this.contextMenuMessage.id})
        this.$snack.info('Message removed')
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.isDeletingContextMessage = false
      }
    },
    
    handleChatEvent({chatKey, event, payload}) {
      if (!chatKey === this.chat.chatKey)
        return
      
      switch (event) {
        case "taskLinkUpdate": return this.updateTaskLinksOnMessages(payload)
      }
    },
    
    taskSelected(task) {
      eventBus.$emit('gotoTask', task)
    },
    
    handleViewTasks(message, event) {
      this.$set(this, "linkedTaskIdsView", [...message.linkedTaskIds])
      this.linkedTaskIdsMenuPos = { y: event.clientY, x: event.clientX };
    },
    
    updateTaskLinksOnMessages(updates) {
      for (const update of updates) {
        const message = this.messages.find(v => v.id === update.id)
        if (message) {
          message.linkedTaskIds = (message.linkedTaskIds || [])
              .concat(update.linkedTaskIds)
              .filter((v, i, a) => a.indexOf(v) === i)
              .sort()
        }
      }
    },
    
    
    async handleLinkMessages({task, messageIds}) {
      this.selectedMessageIds.clear();
      this.selectedMessageIdsSize = 0;
      
      try {
        this.linkingMessages = true;
        
        const linkPayload = await this.$newReq('POST', 'chat/linkMessagesToTask', {
          taskId: task.taskID,
          messageIds
        });
        
        this.updateTaskLinksOnMessages(linkPayload)
        
        // this.messageStr = null
        // setTimeout(() => {
        //   this.$refs.messageTextArea.focus()
        // }, 200)
        //
        // this.loadNewMessages();
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.linkingMessages = false;
      }
    },
    
    handleMessageSelect(message) {
      if (this.selectedMessageIds.has(message.id))
        this.selectedMessageIds.delete(message.id)
      else
        this.selectedMessageIds.add(message.id)
      this.selectedMessageIdsSize = this.selectedMessageIds.size
    },
    
    handleContextMenu(event, message) {
      this.contextMenuMessage = message
      this.messageContextMenuPos = { y: event.clientY, x: event.clientX };
      this.showMessageContextMenu = true;
    },
    
    handleDragStart(event) {
      event.dataTransfer.setData('text', `${JSON.stringify({type: 'chatMessagesLink', data: [...this.selectedMessageIds.values()]})}`);
    },
    
    handleLastSeen({contactId, lastSeen}) {
      if (contactId === this.chat.fkRefContactId) {
        this.contactLastSeen = lastSeen
      }
    },
    
    selectEmoji(e) {
      
      const textarea = this.$refs.messageTextArea.$refs.input;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      
      this.messageStr = this.messageStr || ''
      
      this.messageStr = this.messageStr.substring(0, start) + e.val + this.messageStr.substring(end);
      
      setTimeout(() => {
        textarea.focus()
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = start + e.val.length;
        }, 50)
        
        // this.showEmojiMenu = false
      }, 50)
      
      
    },
    
    async init() {
      // TODO Flush All Info
      // TODO Subscribe to messages for chat key
      // TODO A lot
      
      this.contact = null
      this.messageStr = null
      try {
        this.startingUp = true;
        const {contact} = await this.$newReq('POST', 'chat/messageView/startup', {chatKey: this.chat.chatKey});
        this.contact = contact
        this.loadMessages();
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.startingUp = false;
      }
    },
    
    async loadMessages() {
      try {
        this.loadingMessages = true
        // Get The Channel OR Task Id
        
        const scrollDown = this.messages.length === 0
        
        let resMessages = await this.$newReq('POST', `chat/channelMessages`, {
          chatKey: this.chat.chatKey,
          skip: this.messages.length
        })
        resMessages = resMessages.map(val => new ChatMessage(val))
        this.prepNewMessages(resMessages.concat(this.messages))
        // this.messages = ().map((obj, i, arr) => {
        //
        //   obj.dateTitle = i === 0 || !isSameDay(obj.createdAt, arr[i - 1].createdAt)
        //   if (obj.dateTitle) {
        //     if (isToday(obj.createdAt))
        //       obj.dateTitleStr = 'Today'
        //     else if (isYesterday(obj.createdAt))
        //       obj.dateTitleStr = 'Yesterday'
        //     else if (differenceInDays(new Date(), obj.createdAt) < 6)
        //       obj.dateTitleStr = format(obj.createdAt, 'dddd')
        //     else
        //       obj.dateTitleStr = format(obj.createdAt, 'YYYY/MM/DD')
        //   }
        //   return obj
        // });
        if (scrollDown)
          this.$nextTick(() => {
            this.setVirtualListToBottom();
          });
        markMessagesAsRead(this.chat.chatKey)
        return resMessages.map(val => val.id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingMessages = false
      }
    },
    
    setVirtualListToBottom() {
      if (this.$refs.vsl) {
        // console.log(vsl.value)
        this.$refs.vsl.scrollToBottom();
      } else {
        console.log("NO VALUE")
      }
    },
    
    checkOverFlow() {
      if (this.$refs.vsl) {
        this.overflow = this.$refs.vsl.getScrollSize() > this.$refs.vsl.getClientSize();
      }
    },
    
    onItemRendered() {
      this.checkOverFlow();
    },
    
    setVirtualListToOffset(offset) {
      if (this.$refs.vsl) {
        this.$refs.vsl.scrollToOffset(offset);
      }
    },
    
    vslScrolled(ev) {
      this.showMessageContextMenu = false
      this.linkedTaskIdsView = null
      this.showScrollToBottom = (ev.target.scrollHeight - (ev.target.clientHeight + ev.target.scrollTop)) > 10
    },
    
    async onTotop() {
      if (!this.loadingMessages) {
        const preLength = this.messages.length
        const loadedIds = await this.loadMessages()
        if (loadedIds && preLength) {
          await this.$nextTick()
          const offset = loadedIds.reduce((totalSize, currentId) => {
            return totalSize + this.$refs.vsl.getSize(currentId)
          }, 0);
          
          this.setVirtualListToOffset(offset - 61);
        }
      }
    },
    
    addItemClass(index) {
      const message = new ChatMessage(this.messages[index])
      return !message.isAuthor ? 'bubble-left' : 'bubble-right'
    },
    
    prepNewMessages(messages) {
      this.messages = messages
          .filter((v, i, arr) => arr.findIndex((av) => av.id === v.id) === i)
          .sort((a,b) => a.id > b.id ? 1 : -1)
          .map((obj, i, arr) => {
            obj.dateTitle = i === 0 || !isSameDay(obj.createdAt, arr[i - 1].createdAt)
            if (obj.dateTitle) {
              if (isToday(obj.createdAt))
                obj.dateTitleStr = 'Today'
              else if (isYesterday(obj.createdAt))
                obj.dateTitleStr = 'Yesterday'
              else if (differenceInDays(new Date(), obj.createdAt) < 6)
                obj.dateTitleStr = format(obj.createdAt, 'dddd')
              else
                obj.dateTitleStr = format(obj.createdAt, 'DD/MM/YYYY')
            }
            return obj
          });
    },
    
    async loadNewMessages() {
      try {
        this.loadingMessages = true
        const lastMessageId = this.messages.reduce((agg, msg) => msg.id > agg ? msg.id : agg, 0)
        
        let resMessages = await this.$newReq('POST', `chat/channelMessages/after`, {
          chatKey: this.chat.chatKey,
          lastMessageId
        })
        resMessages = resMessages.map(val => new ChatMessage(val))
        this.prepNewMessages(this.messages.concat(resMessages))
        
        this.$nextTick(() => {
          this.setVirtualListToBottom();
        });
        return resMessages.map(val => val.id)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingMessages = false
      }
    },
  },
  computed: {
    ...mapGetters('userInfo', ["userInfo"]),
    windowFocused() {
      return this.$store.state.chat.windowFocused
    }
  },
}
</script>


<style>

.message-maker textarea {
  margin-top: 1px !important;
  
}

.message-maker .v-label {
  top: unset !important;
}

.chat-back {
  background-repeat: repeat;
  background-size: 230px;
  background-color: rgba(0, 0, 0, 0.05);
}

</style>