<template>
  <div>
    <div @click="shownRelay = !shownRelay">
      <slot name="activator"></slot>
    </div>
    
    
    <v-dialog
        v-if="shownRelay"
        v-model="shownRelay"
        :width="400"
        max-width="90%"
        persistent
        
    >
      <v-card @click="console.log(contact)" v-if="shownRelay && contact" class="scrollable c-d-flex c-flex-column">
        
        <div class="c-flex-grow-0 px-3 primary white--text dark c-d-flex c-align-center">
          <div>
            <slot name="titleIcon">
              <v-icon color="white" left>contact_mail</v-icon>
            </slot>
          </div>
          <div class="c-flex-grow-1 py-2 subheading">
            <slot name="title">Contact Information</slot>
          </div>
          <div>
            <v-btn
                color="white"
                icon
                flat
                @click="shownRelay = false"
                class="mx-0"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </div>
        </div>
        
        <div class="c-flex-grow-1 of-y">
          
          <div class="c-d-flex">
            <div class="pa-3">
              <img src="../../public/contacts.svg" class="nav-item" style="width: 24px">
            </div>
            <div class="c-flex-grow-1">
              <div class="subheading font-weight-bold pt-2">
                {{ contact.fullName }}
              </div>
              <div class="pl-2 subheading c-d-flex c-align-center hover-item hide-parent">
                <div class="subheading c-flex-grow-1 py-2">
                  <v-tooltip right>
                    <template #activator="{on}">
                      <a v-on="on" class="underline-hover" :href="'tel:' + contact.mobile">{{contact.mobile}}</a>
                    </template>
                    Phone
                  </v-tooltip>
                </div>
                <div class="">
<!--                  hide-child-->
                  <v-tooltip left>
                    <template #activator="{on}">
                      <v-btn style="height: 28px" v-on="on" @click.stop="copyStuff(contact.mobile)" small flat icon color="primary">
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                    </template>
                    Copy
                  </v-tooltip>
                </div>
              </div>
              
              <div v-for="email in contact.email" class="pl-2 subheading c-d-flex c-align-center hover-item hide-parent">
                <div class="body-2 c-flex-grow-1 py-2">
                  <v-tooltip right>
                    <template #activator="{on}">
                      <a v-on="on" class="underline-hover" :href="'mailto:' + email">{{email}}</a>
                    </template>
                    Mail
                  </v-tooltip>
                  
                </div>
                <div class="">
                  <v-tooltip left>
                    <template #activator="{on}">
                      <v-btn style="height: 28px" v-on="on" @click.stop="copyStuff(email)" small flat icon color="primary">
                        <v-icon small>content_copy</v-icon>
                      </v-btn>
                    </template>
                    Copy
                  </v-tooltip>
                </div>
              </div>
            </div>
          </div>
          
          <div class="c-d-flex c-justify-end pb-1" >
            <v-btn @click="shownRelay = false" color="secondary" small>Done</v-btn>
          </div>
        </div>
        
        
        
      </v-card>
      <!--            <template v-slot:activator="{ on }">-->
      <!--                -->
      
      <!--            </template>-->
      
      
    </v-dialog>
  </div>

</template>

<script>

import {copyText} from "@/codeFunctions";

export default {
  components: {
  },
  props: {
    shown: {
      type: Boolean,
      default: false
    },
    contact: {
      type: [Object, null],
      default: null
    },
  },
  data() {
    return {
      selfShown: false,
      console,
    }
  },
  methods: {
    copyStuff(str) {
      copyText(document, str);
      this.$snack.info(str + ' Copied')
    },
  },
  computed: {
    shownRelay: {
      get() {
        return this.shown || this.selfShown
      },
      set(val) {
        this.selfShown = val
        this.$emit("update:shown", val)
      }
    }
  },
}
</script>

<style scoped>
.underline-hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

.underline-hover:hover {
  text-decoration: underline;
}
</style>