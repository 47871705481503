import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'

import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
Vue.use(VueVirtualScroller);

import './Styles/main.css'
import './Styles/dark-theme-overrides.css'

export const eventBus = new Vue();

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

import store from './Store/store'

Vue.config.productionTip = false;

import {newReq, newReqDownloadFile, newReqUpload, newReqUploadMultipleFormValues} from '@/axiosRequest';
import {newDownload, getRepoDocs} from "@/Document Repository/docRepoMan";
import {createCalendarEvent, openCalendarEvent} from "@/Calendar View/Event Manager/EventManager";

Vue.prototype.$newReq = newReq;
Vue.prototype.$newReqDownloadFile = newReqDownloadFile;
Vue.prototype.$newReqUpload = newReqUpload;
Vue.prototype.$newReqUploadMultiple = newReqUploadMultipleFormValues;
Vue.prototype.$newDownload = newDownload;
Vue.prototype.$getRepoDocs = getRepoDocs;
Vue.prototype.$openCalendarEvent = openCalendarEvent;
Vue.prototype.$createCalendarEvent = createCalendarEvent;

Vue.prototype.$con = console;


const snack = require('@/snack');
Vue.prototype.$snack = snack;

import VueHtmlToPaper from 'vue-html-to-paper';

Vue.prototype.$con = console;

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);

import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)

// import VueApexCharts from 'vue-apexcharts'
// Vue.use(VueApexCharts)
//
// Vue.component('apexchart', VueApexCharts);

const app = new Vue({
        render: h => h(App),
        store: store,
    })

dbGlobalMethods.initialize().then(async () => {
    const themePref = (await dbGlobalMethods.getConfigValue('theme'))?.val || 'auto'
    store.dispatch('setTheme', themePref);
    app.$mount('#app');
})

import activityChecker from './activityChecker.vue'
import {dbGlobalMethods} from "@/localDBFunction";

// socket.io-client": "^2.3.0",
