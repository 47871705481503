<template>
    <div>
        <div @click="showAdd = !showAdd">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                :show-dialog="showAdd"
                title="Add Workflow Group"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="px-1 pt-1">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Select Workflow Group:
                        </div>
                    </div>
                    <v-text-field
                            label="Filter Workflow Groups"
                            single-line
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="pa-0 ma-0"
                            ref="WFGroupFilter"
                            v-model="workflowGroupFilterString"
                            prepend-inner-icon="search"
                    />
                    <div class="scrollable" style="height: 300px">
                        <div v-for="item in filteredSelectedWorkflowGroups" :key="item.id" >
                            <div
                                    :class="selectedWorkflowGroup != null && selectedWorkflowGroup.id == item.id ? 'selected-item' : 'hover-item'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="selectedWorkflowGroup = item">
                                        <div class="body-1" :class="selectedWorkflowGroup != null && selectedWorkflowGroup.id == item.id ? 'primary--text' : ''">
                                            {{item.description}}
                                        </div>
                                        <div class="caption text-truncate" :class="Array.isArray(item.members) ? 'greyType--text' : 'red--text'" style="overflow-x: hidden">
                                            {{Array.isArray(item.members) ? item.members.map(obj => {return obj.description}).join(', ') : 'NO USER GROUPS ASSIGNED'}}
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-divider/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="selectedWorkflowGroup == null || addingWorkflowGroup"
                            :loading="addingWorkflowGroup"
                            @click="addWorkflowGroup"
                    >
                        Add Workflow Group
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import InputDlg from "../../../../../components/General/InputDlg";

    export default {
        components: {
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedWorkflow: Object,
            workflowGroups: Array,
        },
        data() {
            return {
                showAdd: false,
                workflowGroupFilterString: null,
                selectedWorkflowGroup: null,
                addingWorkflowGroup: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.workflowGroupFilterString = null;
                    this.selectedWorkflowGroup = null;
                    this.addingWorkflowGroup = false;
                    this.$nextTick(() => {
                        this.$refs.WFGroupFilter.focus();
                    })
                }
            },
        },
        methods: {
            addWorkflowGroup() {
                this.addingWorkflowGroup = true;
                this.fetch({method: 'POST', url:`/workflowManager/security/workflowGroup/addMember/${this.userInfo.entityID}/${this.selectedWorkflow.id}/${this.selectedWorkflowGroup.id}`})
                    .then(() => {
                        this.$emit('workflowGroupAdded', this.selectedWorkflowGroup);
                        this.showAdd = false;
                        this.addingWorkflowGroup = false;
                        this.showSnack('Info', 'Workflow Group Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingWorkflowGroup = false;
                    })
            }
        },
        computed: {
            filteredSelectedWorkflowGroups() {
                    return this.blankString(this.workflowGroupFilterString)
                        ? this.workflowGroups.filter(obj => true)
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1)
                        : this.workflowGroups
                            .reduce((list, workflowGroup) => {
                                if (
                                    [workflowGroup.description].concat(
                                        !Array.isArray(workflowGroup.members)
                                            ? null
                                            : workflowGroup.members.map(userGroup => {
                                                return [userGroup.description].concat(
                                                    userGroup.members.map(user => {
                                                        return typeof user != 'undefined' ? user.description : null
                                                    }).join(', ')
                                                ).join(', ')
                                            }).join(', ')
                                    ).join(', ').toLowerCase().includes(this.workflowGroupFilterString.toLowerCase())
                                ) {
                                    list.push(workflowGroup)
                                }
                                return list
                            }, [])
                            .sort((a,b) => a['description'] < b['description'] ? -1 : 1);

            },
        }

    }
</script>

<style scoped>

</style>
