import {arrSplice} from "@/codeFunctions";
import {newReq} from "@/axiosRequest"
import store from "@/Store/store"
import {EventEmitter} from "events"


export class DatasetBinder {
    task = null;
    localData = null;
    detailController = null;

    // Folders
    folderSelectFields = null;
    loadingFolders = [];
    folderDatasets = {};

    // Datasets
    datasetSelectFields = null;
    datasetRuleRef = {};

    // DatasetValues
    loadingDatasets = [];
    datasets = {};

    dsDocuments = [];
    dsMails = [];
    emitter = new EventEmitter()

    get userInfo() {
        return store.getters["userInfo/userInfo"];
    }

    get folderSelectFieldFolderRef() {
        return this.folderSelectFields.reduce((obj, field) => {
            obj[field.fieldID] = Number(field.value);
            return obj
        }, {})
    }

    get datasetSelectFieldsByFolder() {
        return this.datasetSelectFields.reduce((obj, dsSelectField) => {

            if (dsSelectField.config.folder && this.folderSelectFieldFolderRef[dsSelectField.config.folder]) {
                if (!obj[this.folderSelectFieldFolderRef[dsSelectField.config.folder]])
                    obj[this.folderSelectFieldFolderRef[dsSelectField.config.folder]] = []

                obj[this.folderSelectFieldFolderRef[dsSelectField.config.folder]].push(dsSelectField)
            } else {
                if (!obj[this.task.folderID])
                    obj[this.task.folderID] = []

                obj[this.task.folderID].push(dsSelectField)
            }

            return obj
        }, {})
    }

    get datasetSelectFieldsRef() {
        return this.datasetSelectFields.reduce((obj, dsf) => {
            obj[dsf.fieldID] = dsf
            return obj
        }, {})
    }

    get datasetSelectFieldsByValue() {
        return this.datasetSelectFields
            .filter(dsSelectField => dsSelectField.value)
            .reduce((obj, dsSelectField) => {
                if (!obj[dsSelectField.value])
                    obj[dsSelectField.value] = [];
                obj[dsSelectField.value].push(dsSelectField);
                return obj
            }, {})
    }

    constructor(localData, task, detailController) {
        this.task = task;
        this.localData = localData;
        this.detailController = detailController;

        // console.log('task: ', this.task);
        // console.log('detailController: ', this.detailController);
        // console.log('localData: ', this.localData);

        // Set Folder Fields
        this.folderSelectFields = this.task.structure.fields.filter(field => field.fieldType === 21)

        // Set Dataset Select Fields
        this.datasetSelectFields = this.task.structure.fields.filter(field => field.fieldType === 9)
        // Load Up Dataset Values
        this.datasetSelectFields
            .filter(dsField => dsField.value)
            .forEach(dsField => {
                this.loadDataset(dsField.value)
            })

        // Set The Dataset Rule Ref
        this.datasetRuleRef = this.task.structure.fieldSetRules.reduce((obj, fs) => {
            obj[fs.id] = {
                ...fs,
                securityGroups: JSON.parse(fs.securityGroups)
            }
            return obj
        }, {})

        // Init Folder Data
        this.initFolders();
    }

    addDataset(ds) {

        ds.datasetValueNameSafe = ds.datasetValueName || ''

        if (!this.folderDatasets[ds.folderID])
            this.folderDatasets[ds.folderID] = {};

        if (!this.folderDatasets[ds.folderID][ds.datasetID])
            this.folderDatasets[ds.folderID][ds.datasetID] = [];

        this.folderDatasets[ds.folderID][ds.datasetID].push(ds);
    }

    initFolders() {
        let loadedFolders = [this.task.folderID];
        this.loadFolderDatasets(this.task.folderID)
        for (const folderField of this.folderSelectFields) {
            if (folderField.value && !loadedFolders.includes(folderField.value)) {
                loadedFolders.push(folderField.value)
                this.loadFolderDatasets(folderField.value, folderField.fieldID)
            }
        }
    }

    loadFolderDatasets(folderID) {
        folderID = Number(folderID)
        if (!this.loadingFolders.includes(folderID) && !this.folderDatasets[folderID]) {
            this.loadingFolders.push(folderID);
            newReq('GET', `dsLink/datasets/${this.userInfo.entityID}/${this.userInfo.userID}/${folderID}`)
                .then(data => {
                    data.forEach(ds => {
                        this.addDataset(ds)
                    })
                    const folderDatasetFields = this.datasetSelectFieldsByFolder[folderID];

                    if (folderDatasetFields)
                        folderDatasetFields.forEach(dsSelectField => {
                            // Check That There Is No Value Present
                            this.populateFolderDataset(dsSelectField, folderID)
                        })

                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    arrSplice(this.loadingFolders, arrFolderID => arrFolderID === folderID)
                    this.emitter.emit('loaded')
                })
        } else {
            if (!this.loadingFolders.includes(folderID)) {
                const folderDatasetFields = this.datasetSelectFieldsByFolder[folderID];
                folderDatasetFields.forEach(dsSelectField => {
                    // Check That There Is No Value Present
                    this.populateFolderDataset(dsSelectField, folderID)
                })
                this.emitter.emit('loaded')
            }

        }
    }

    populateFolderDataset(dsSelectField, folderID) {

        // Check If YOu Should Null The Value
        if (dsSelectField.value && Number(dsSelectField.bindRef) !== Number(folderID)) {
            // Folder Changed With An Existing Value
            console.log('Null Set Here', dsSelectField, folderID)
            dsSelectField.value = null;
            dsSelectField.allowNull = true;
            this.clearLinkedFields(dsSelectField)
        }

        if (!dsSelectField.value) {
            // Get The Datasets Of The Folder For The DS Select Field
            const fDatasets = this.folderDatasets[folderID];

            const arrDatasets = fDatasets ? fDatasets[dsSelectField.config.dataset] || [] : []

            if (!(dsSelectField.config?.blockAutoPopulate)) {
                // Check That Auto Populate Is Enabled
                if (!this.datasetRuleRef[dsSelectField.config.dataset].allowMultiple) {
                    // Single Type DS Select NOW Attempt to Auto Populate
                    if (arrDatasets.length) {
                        dsSelectField.value = [...arrDatasets].pop().memberID
                        dsSelectField.bindRef = folderID
                    }
                } else {
                    if (arrDatasets.length === 1) {
                        if (!(dsSelectField.config && dsSelectField.config.createOnly)) {
                            dsSelectField.value = [...arrDatasets].pop().memberID
                            dsSelectField.bindRef = folderID
                        }
                    }
                }
            }
        }
    }

    loadDataset(datasetMemberID) {
        datasetMemberID = Number(datasetMemberID)

        if (!this.loadingDatasets.includes(datasetMemberID) && !this.folderDatasets[datasetMemberID]) {
            this.loadingDatasets.push(datasetMemberID);
            newReq('GET', `dsLink/datasetValues/${this.userInfo.entityID}/${this.userInfo.userID}/${datasetMemberID}`)
                .then(data => {
                    this.datasets[datasetMemberID] = data
                    data.documents.forEach(doc => {this.dsDocuments.push(doc)})
                    data.mails.forEach(mail => {this.dsMails.push(mail)})
                    // console.log(data)
                    this.populateLinkedFields(datasetMemberID)
                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    arrSplice(this.loadingDatasets, arrFolderID => arrFolderID === datasetMemberID)
                })
        }
    }

    populateLinkedFields(datasetMemberID) {
        // Build A List Of All Linked Fields
        const dsSelectFields = this.datasetSelectFieldsByValue[datasetMemberID]
        const dsSelectFieldsIDs = Object.keys(dsSelectFields)
            .map(key => {return dsSelectFields[key]})
            .map(field => {return field.fieldID});
        // Get A List Of All Fields Linked To Those DS Select Fields
        const linkedAutoPopulateFields = this.task.structure.fields
            .filter(field =>
                field.linkLookup
                && dsSelectFieldsIDs.includes(field.linkLookup.fieldSetSelectField)
                && (typeof field.linkLookup.autoPopulate == 'undefined' || field.linkLookup.autoPopulate)
            )

        // Build A Ref List Of DS Fields
        const datasetFieldsByID = (this.datasets[datasetMemberID] || {fields: []}).fields
            .reduce((obj, field) => {
                obj[field.datasetFieldID] = field;
                return obj;
            }, {});

        // Loop Through Fields And Check If An Update Is Needed
        for (const field of linkedAutoPopulateFields) {

            // console.log(field.description, datasetMemberID)

            // Check If The Bind Ref Matches The DS Member If Not Then Clear The Value
            if (field.bindRef && Number(field.bindRef) !== Number(datasetMemberID)) {
                // console.log('Set Null Ran')
                field.value = null;
                field.value_type = null;
                field.bindRef = null;
                field.allowNull = true;
            }

            // Check If Field Has A Value If Not Then Populate
            if (!field.value && datasetFieldsByID[field.linkLookup.field]) {
                field.value = datasetFieldsByID[field.linkLookup.field].value;
                field.value_type = datasetFieldsByID[field.linkLookup.field].valueType;
                field.bindRef = datasetMemberID;
            }

        }
    }

    clearLinkedFields(dsSelectField) {

        // Get A List Of All Fields Linked To Those DS Select Fields
        const linkedAutoPopulateFields = this.task.structure.fields
            .filter(field =>
                field.linkLookup
                && Number(dsSelectField.fieldID) === Number(field.linkLookup.fieldSetSelectField)
                && field.linkLookup.autoPopulate
            )

        for (const field of linkedAutoPopulateFields) {
            field.value = null;
            field.bindRef = dsSelectField.value;
            field.allowNull = true;
        }
    }
}