<template>
  <v-card class="my-1 mx-1" style="width: 100%" @dragover.prevent="$event.dataTransfer.dropEffect = 'move'" @drop="dropEvent">
    
    <div class="c-d-flex c-flex-column" >
      <v-layout row wrap column>
        <v-flex xs12 :order-xs2="!task">
          <div class="c-d-flex c-align-center pa-2" style="position: relative; background: var(--card-back)">
            <transition
                enter-active-class="scale-in-center"
                leave-active-class="scale-out-center"
                mode="out-in"
            >
              <div key="textArea" class="c-flex-grow-1 c-d-flex c-align-center" v-if="!audioUrl && !isRecording" >
                <v-menu v-model="showEmojiMenu" :height="300" offset-y bottom :close-on-content-click="false">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        class="pa-0 ma-0"
                        :disabled="sendingMessage"
                        v-on="on"
                    >
                      <v-icon>mood</v-icon>
                    </v-btn>
                  </template>
                  <div>
                    <emoji-picker v-if="showEmojiMenu" style="height: 300px" @input="selectEmoji"/>
                  </div>
                </v-menu>
                <v-menu
                    v-model="showMentionMenu"
                    :close-on-content-click="true"
                    :open-on-click="false"
                    origin="bottom"
                    :top="!task"
                    :bottom="!!task"
                    offset-y
                    ref="accessMenu"
                    width="400px"
                >
                  <template #activator="{on}">
                    <v-textarea
                        v-on="on"
                        :autofocus="true"
                        ref="messageTextArea"
                        class="py-0 my-0 message-maker"
                        single-line
                        solo
                        flat
                        hide-details
                        label="Type your message here"
                        :rows="1"
                        :auto-grow="true"
                        v-model="messageText"
                        @keydown="handleKeydown"
                        :loading="sendingMessage"
                        @input="handleInput"
                        :disabled="sendingMessage"
                    >
                    </v-textarea>
                  </template>
                  <v-card v-if="showMentionMenu" style="height: 400px">
                    <v-list class="of-y py-0 my-0" style="background: var(--card-back) !important;">
                      <v-list-tile
                          v-for="(user, i) in filteredAccess"
                          :key="user.id"
                          @click="selectMentionList(user)"
                      >
                        <v-list-tile-avatar class="body-2" :color="user.color.hex" :style="{color: user.color.fontColor}">
                          {{user.initials}}
                        </v-list-tile-avatar>
                        {{ user.name }}
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
                <v-menu>
                  <template #activator="{on}">
                    <v-btn
                        v-on="on"
                        icon
                        class="pa-0 ma-0"
                        :disabled="sendingMessage"
                    >
                      <v-icon style="transform: rotate(-45deg)">attach_file</v-icon>
                    </v-btn>
                  </template>
                  
                  <v-card>
                    <v-list>
                      <v-list-tile @click="$refs.photoUpload.click()">
                        <v-list-tile-avatar><v-icon>photo_camera</v-icon></v-list-tile-avatar>
                        <v-list-tile-title>Camera</v-list-tile-title>
                      </v-list-tile>
                      
                      <v-list-tile @click="$refs.imageUpload.click()">
                        <v-list-tile-avatar><v-icon>crop_original</v-icon></v-list-tile-avatar>
                        <v-list-tile-title>Images</v-list-tile-title>
                      </v-list-tile>
                      
                      <v-list-tile @click="$refs.documentUpload.click()">
                        <v-list-tile-avatar><v-icon>insert_drive_file</v-icon></v-list-tile-avatar>
                        <v-list-tile-title>Documents</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-card>
                </v-menu>
              </div>
              
              <div v-else-if="audioUrl" class="c-flex-grow-1 c-d-flex c-align-center c-justify-end">
                <app-voice-note-player :url="audioUrl" @delete="audioUrl = null" :show-delete="true"/>
              </div>
              
              <div v-else class="c-flex-grow-1 c-d-flex c-align-center c-justify-end">
                
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                        icon
                        variant="text"
                        v-on="on"
                        @click="() => stopRecording(true)"
                    >
                      <v-icon>delete_outline</v-icon>
                    </v-btn>
                  </template>
                  Discard voice message
                </v-tooltip>
                
                <div class=" red heartbeat" style="width: 18px; height: 18px; border-radius: 50%">
                
                </div>
                
                <div class="px-2 d-flex justify-end" style="width: 56px;">{{ recordingTime }}</div>
                
                <canvas ref="canvas" width="160" height="44"></canvas>
                
                <v-tooltip top>
                  <template #activator="{ on }">
                    <v-btn
                        color="error"
                        icon
                        v-on="on"
                        @click="() => stopRecording()"
                    >
                      <v-icon>stop</v-icon>
                    </v-btn>
                  </template>
                  Stop recording
                </v-tooltip>
              </div>
            </transition>
            
            
            <transition
                enter-active-class="scale-in-center"
                leave-active-class="scale-out-center"
                mode="out-in"
            >
              <v-btn
                  :key="allowSend ? 'send' : 'mic'"
                  class="ml-2"
                  variant="elevated"
                  icon
                  color="secondary"
                  :disabled="sendingMessage"
                  :loading="sendingMessage"
                  @click="allowSend ? sendMessage() : startRecording()"
              >
                <v-icon>{{allowSend ? 'send' : 'mic'}}</v-icon>
              </v-btn>
            </transition>
            
            
            <!--        <v-btn-->
            <!--            icon-->
            <!--            class="pa-0 ma-0 mx-2"-->
            <!--            color="secondary"-->
            <!--            :loading="sendingMessage"-->
            <!--            :disabled="sendingMessage"-->
            <!--            @click="sendMessage"-->
            <!--        >-->
            <!--          <v-icon>send</v-icon>-->
            <!--        </v-btn>-->
          </div>
        </v-flex>
        <v-flex xs12 :order-xs1="!!task" v-if="viewImages.length || viewFiles.length || viewFiles">
          
          <div class="c-d-flex c-align-center c-justify-center px-2 pb-1" v-if="viewImages.length">
            
            <div class="c-flex-grow-0 px-1" v-for="(viewSrc, i) in viewImages"
                 style="position: relative; transition: all 300ms ease-in-out" :key="viewSrc">
              <!--          <v-card :max-height="200">-->
              <img :src="viewSrc" ref="image"
                   style="width: 100%; max-height: 200px; object-fit: contain; border-radius: 5px"/>
              
              <div class="px-1" style="position: absolute; right: 0; top: 0;">
                <v-btn
                    small
                    color="error"
                    icon
                    @click="viewImages = viewImages.filter((val, fiI) => fiI !== i)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
            </div>
          
          
          </div>
          
          <div class="px-2" v-if="viewFiles.length">
            
            <v-card flat class="py-0 pl-2 my-0 my-1 c-d-flex c-align-center" v-for="(fileInfo, i) in viewFiles" style="transition: all 300ms ease-in-out; height: 48px; width: 100%" :key="fileInfo.name">
              <!--          <v-card :max-height="200">-->
              
              <div class="pr-2">
                <v-img width="32px" height="32px" style="border-radius: 5px" v-if="fileInfo.icon" alt="icon" :src="`types/${fileInfo.icon}`"/>
                <v-icon color="primary" :size="32" v-else>insert_drive_file</v-icon>
              </div>
              
              <div class="text-truncate c-flex-grow-1 " >
                <div class="text-truncate text-body-2">
                  {{fileInfo.name}}
                </div>
                <div class="text-caption" style="line-height: 1">
                  {{sizeToStr(fileInfo.size || 0)}}
                </div>
              </div>
              
              <div class="pr-1">
                <v-btn
                    color="error"
                    icon
                    text
                    flat
                    small
                    @click="viewFiles = viewFiles.filter((val, fiI) => fiI !== i)"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </div>
            </v-card>
          
          
          </div>
          
          <div class="px-2 pb-1" v-if="viewTaskDocuments.length">
            
            <app-general-file-viewer
                class="my-1"
                v-for="(att, i) in viewTaskDocuments"
                :filename="att.filename"
                :document-id="att.documentId"
                :hide-download="true"
            >
              <template #actions>
                <v-btn
                    icon
                    depressed
                    class="ma-0"
                    @click="viewTaskDocuments = viewTaskDocuments.filter((v, vi) => i !== vi)"
                >
                  <v-icon color="error">close</v-icon>
                </v-btn>
              </template>
            </app-general-file-viewer>
          </div>
        </v-flex>
        <v-flex xs12 :order-xs1="!!task" v-if="showNewInformationRequest">
          <v-divider></v-divider>
          <app-new-information-request
              ref="informationRequest"
              :edit-request="informationRequestTemplate"
              :filter-target-folder="task.folderID"
              :loading="sendingMessage"
              @cancel="showNewInformationRequest = false; informationRequestTemplate = null"
              @send="sendMessage"
          />
        </v-flex>
      </v-layout>
    </div>
    
    <input
        ref="photoUpload"
        type="file"
        :multiple="false"
        accept="image/*"
        capture="environment"
        @input="imageSelected"
    />
    
    <input
        ref="imageUpload"
        type="file"
        :multiple="true"
        accept="image/*"
        @input="imagesSelected"
    />
    
    <input
        ref="documentUpload"
        type="file"
        :multiple="true"
        @input="handleFiles($event.target.files || [])"
    />
    
    
  </v-card>


</template>

<script>

import EmojiPicker from "@/components/General/EmojiPicker/EmojiPicker.vue";
import Recorder from 'opus-recorder';
import encoderPath from 'opus-recorder/dist/encoderWorker.min.js';
import {OutboxMessage} from "@/chat/chatFunctions";
import AppVoiceNotePlayer from "@/Tasks/TaskView V2/components/Chat Message/AppVoiceNotePlayer.vue";
import mime from "mime-types";
import AppGeneralFileViewer from "@/components/General/AppGeneralFileViewer.vue";
import AppNewInformationRequest from "@/Tasks/TaskView V2/components/Chat Message/AppNewInformationRequest.vue";
import {mapGetters} from "vuex";
import {getInitialsAndColor} from "@/codeFunctions";

export class InformationRequestTemplate {
  taskId = null;
  createdBy = null;
  createdAt = null;
  prompt = null;
  targetId = null;
  fields = [];
  
  constructor(obj = {}) {
    Object.assign(this, JSON.parse(JSON.stringify(obj)))
  }
}

export class InformationRequestField {

}

export default {
  components: {AppGeneralFileViewer, AppVoiceNotePlayer, EmojiPicker, AppNewInformationRequest},
  props: {
    task: Object,
    chatKey: String
  },
  data() {
    return {
      console,
      messageText: '',
      sendingMessage: false,
      showEmojiMenu: false,
      contactLastSeen: null,
      
      isRecording: null,
      audioUrl: null,
      
      recorder: null,
      audioContext: null,
      analyser: null,
      mediaStream: null,
      bufferLength: null,
      dataArray: null,
      recordingStartTime: null,
      deleteAfterStop: false,
      sendAfterStop: false,
      canvasContext: null,
      animationFrameId: null,
      recordingTime: '00:00',
      viewImages: [],
      viewFiles: [],
      viewTaskDocuments: [],
      showNewInformationRequest: false,
      informationRequestTemplate: null,
      isLoadingAccess: false,
      chatAccess: [],
      showMentionMenu: false,
      mentionSearch: '',
      mentionStartIndex: -1,
      lastInput: null
    }
  },
  
  mounted() {
    // this.setupRecorder();
  },
  
  beforeDestroy() {
    if (this.recorder) {
      this.recorder.close();
    }
    if (this.audioContext) {
      this.audioContext.close();
    }
    cancelAnimationFrame(this.animationFrameId);
  },
  
  watch: {
    chatKey: {
      immediate: true,
      handler(val) {
        this.loadAccess()
      }
    },
    
    filteredAccess(val) {
      // if (this.$refs.accessMenu) {
      //   console.log(this.$refs.accessMenu)
      //   this.$refs.accessMenu.activate()
      // }
      
    }
  },
  
  methods: {
    
    async loadAccess() {
      try {
        this.isLoadingAccess = true
        this.chatAccess = (await this.$newReq('POST', 'chat/getChatAccess', {chatKey: this.chatKey})).map(v => {
          const {initials, color} = getInitialsAndColor(v.name)
          v.initials = initials
          v.color = color
          return v
        });
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoadingAccess = false
      }
    },
    
    handleInput() {
      const cursorPosition = this.$refs.messageTextArea.$el.querySelector('textarea').selectionStart
      const textBeforeCursor = this.messageText.slice(0, cursorPosition)
      const lastAtSymbol = textBeforeCursor.lastIndexOf('@')
      
      if (lastAtSymbol !== -1) {
        const textAfterAt = textBeforeCursor.slice(lastAtSymbol + 1)
        if (!textAfterAt.includes(' ')) {
          this.mentionSearch = textAfterAt
          this.mentionStartIndex = lastAtSymbol
          this.showMentionMenu = true
          
          // Position the menu near the cursor
          const textarea = this.$refs.messageTextArea.$el.querySelector('textarea')
          return
        }
      }
      
      this.showMentionMenu = false
    },
    
    selectMentionList(user) {
      this.selectMention(user)
    },
    
    selectMention(user) {
      this.lastInput = new Date()
      const beforeMention = this.messageText.slice(0, this.mentionStartIndex)
      const afterMention = this.messageText.slice(this.$refs.messageTextArea.$el.querySelector('textarea').selectionStart)
      this.messageText = `${beforeMention}@${user.name} ${afterMention}`
      this.showMentionMenu = false
      this.mentionSearch = ''
      this.mentionStartIndex = -1
    },
    
    handleKeydown(e) {
      if (!this.showMentionMenu) {
        if (e.key === 'Enter' || e.key === 'Return') {
          e.preventDefault()
          e.stopPropagation()
          this.sendMessage()
          return
        }
      }
      
      if (e.key === 'Escape') {
        this.showMentionMenu = false
      } else if (e.key === 'Enter' && this.filteredAccess.length > 0) {
        e.preventDefault()
        this.delayedInput(this.filteredAccess[0])
        
      }
    },
    
    delayedInput(user) {
      setTimeout(() => {
        if (!this.lastInput || (new Date() - this.lastInput > 500))
        this.selectMention(user)
      }, 300)
    },
    
    dropEvent(e) {
      try {
        const dropData = JSON.parse(e.dataTransfer.getData("text") || '{}')
        
        // Task Document
        if (dropData?.dragType === 'document' && dropData.data) {
          const taskDocument = this.task.structure.documents.find(v => v.documentDBID === dropData.data)
          
          let filename = (taskDocument.alias || taskDocument.fileName).trim()
          
          if (filename.split('.').pop().toLowerCase() !== (taskDocument.extention || '').toLowerCase())
            filename = `${filename}.${taskDocument.extention}`
          
          const mimeType = mime.lookup(filename)
          const mimeTypeCat = mimeType.split('/').shift()
          
          let attType = null
          
          if (mimeType === 'application/pdf')
            attType = 'taskPdf'
          else if (mimeTypeCat === 'image')
            attType = 'taskImage'
          else if (mimeTypeCat === 'audio')
            attType = 'taskAudio'
          else attType = 'taskFile'
          
          
          this.viewTaskDocuments.push({
            type: attType,
            mime: mimeType,
            filename,
            documentDbId: taskDocument.documentDBID,
            documentId: taskDocument.documentID
          })
        } else if (dropData.type === 'workflowField') {
          if (!this.informationRequestTemplate) {
            this.informationRequestTemplate = new InformationRequestTemplate({
              createdBy: this.userInfo.userID,
              createdAt: new Date(),
              taskId: this.task.taskID
            })
            this.informationRequestTemplate.fields.push(dropData.payload)
            this.informationRequestTemplate.fields = this.informationRequestTemplate.fields.filter((v, i, a) => a.findIndex(av => av.fieldID === v.fieldID) === i)
            this.showNewInformationRequest = true
          } else {
            const request = this.$refs.informationRequest.request
            request.fields.push(dropData.payload)
            request.fields = request.fields.filter((v, i, a) => a.findIndex(av => av.fieldID === v.fieldID) === i)
          }
        }
      } catch (e) {
        // console.log(e)
      }
    },
    
    sizeToStr(bytes) {
      if (bytes < 1024)
        return `${bytes} bytes`;
      else if (bytes < (1024*1024))
        return `${+(bytes / 1024).toFixed(2)} KB`;
      else
        return `${+(bytes / (1024*1024)).toFixed(2)} MB`;
    },
    
    imageSelected(ev) {
      const selectedImage = ev.target.files[0];
      if (selectedImage) {
        const imageUrl = URL.createObjectURL(selectedImage);
        this.viewImages.push(imageUrl);
      }
    },

    imagesSelected(ev) {
      if (ev.target.files && ev.target.files.length) {
        for (const image of ev.target.files) {
          if (image) {
            const imageUrl = URL.createObjectURL(image);
            this.viewImages.push(imageUrl);
          }
        }
      }
    },

    handleFiles(files) {
      for (const file of files) {
        if (file) {
          if ((file.type || '').split('/').shift().toLowerCase() === 'image') {
            this.viewImages.push(URL.createObjectURL(file));
          } else {
            const ext = mime.extension(file.type) || null
            this.viewFiles.push({
              file,
              name: file.name,
              size: file.size,
              icon: (ext && this.$store.state.typeCatalogSet.has(ext)) ? `${ext}.svg` : null
            });
          }
        }
      }
    },
    
    stopRecording(cancel = false) {
      if (cancel) {
        this.deleteAfterStop = cancel;
      }
      if (this.recorder) {
        this.recorder.stop();
      }
      this.isRecording = false;
      this.stopRecordingTimer();
      this.recordingTime = '00:00';
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop());
      }
      if (this.audioContext) {
        this.audioContext.close();
        this.audioContext = null;
      }
      this.canvasContext = null;  // Reset the canvas context
    },
    
    async setupRecorder() {
      try {
        this.recorder = new Recorder({
          encoderPath,
          mediaTrackConstraints: true,
          encoderSampleRate: 48000,
          encoderApplication: 2048,
          streamPages: true,
        });
        
        this.recorder.ondataavailable = (typedArray) => {
          this.audioChunks.push(typedArray.buffer);
        };
        
        let vm = this
        
        this.recorder.onstart = async () => {
          vm.audioContext = new AudioContext();
          vm.analyser = vm.audioContext.createAnalyser();
          vm.mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
          const source = vm.audioContext.createMediaStreamSource(vm.mediaStream);
          source.connect(vm.analyser);
          vm.analyser.fftSize = 2048;
          vm.bufferLength = vm.analyser.frequencyBinCount;
          vm.dataArray = new Uint8Array(vm.bufferLength);
          
          if (vm.$refs.canvas) {
            vm.canvasContext = vm.$refs.canvas.getContext('2d');  // Initialize the canvas context here
            vm.drawWaveform();  // Start drawing waveform
          }
        };
        
        this.recorder.onstop = () => {
          if (vm.deleteAfterStop) {
            vm.canvasContext = null;
            return;
          }
          if (vm.mediaStream)
            vm.mediaStream.getTracks().forEach(track => track.stop());
          if (vm.audioContext)
            vm.audioContext.close();
          cancelAnimationFrame(vm.animationFrameId);
          vm.analyser = null;
          vm.dataArray = null;
          vm.bufferLength = null;
          const blob = new Blob(vm.audioChunks, {type: 'audio/ogg'});
          vm.audioUrl = URL.createObjectURL(blob);
          vm.audioChunks = [];
          vm.canvasContext = null;  // Reset the canvas context after stopping
          
          if (vm.sendAfterStop) {
            vm.sendAfterStop = false;
            vm.sendMessage();
          }
        };
      } catch (e) {
        console.log(e)
      }
    },
    
    async startRecording() {
      try {
        
        
        if (!this.recorder) {
          this.setupRecorder();
        }
        this.audioChunks = [];  // Clear previous audio chunks
        if (this.mediaStream) {
          this.mediaStream.getTracks().forEach(track => track.stop());
        }
        // Request permission to use the microphone
        this.mediaStream = await navigator.mediaDevices.getUserMedia({audio: true});
        this.audioContext = new AudioContext();  // Initialize AudioContext
        this.analyser = this.audioContext.createAnalyser();  // Initialize Analyser
        const source = this.audioContext.createMediaStreamSource(this.mediaStream);  // Create MediaStreamSource
        source.connect(this.analyser);
        this.analyser.fftSize = 2048;
        this.bufferLength = this.analyser.frequencyBinCount;
        this.dataArray = new Uint8Array(this.bufferLength);
        this.recorder.start().catch(error => {
          console.error('Error starting the recorder:', error);
          // Handle different types of errors here
          if (error.name === 'NotAllowedError') {
            alert('Permissions to access the microphone were denied.');
          } else if (error.name === 'AbortError') {
            console.warn('User aborted the request.');
          } else {
            alert('An error occurred while trying to start recording.');
          }
        });
        
        this.deleteAfterStop = false;
        this.sendAfterStop = false;
        this.isRecording = true;
        this.recordingStartTime = Date.now();
        
        const waitFn = async () => {
          if (!this.$refs.canvas) {
            return await new Promise((resolve) => setTimeout(() => {
              resolve(waitFn())
            }, 100));
          } else {
            return this.$refs.canvas;
          }
        };
        
        const canvasValue = await waitFn();
        if (this.$refs.canvas) {
          this.canvasContext = this.$refs.canvas.getContext('2d');  // Reinitialize the canvas context
          this.drawWaveform();
        }
        this.startRecordingTimer();
      } catch (error) {
        console.error("Error starting recording: ", error);
        // Provide feedback to the user
        alert("An error occurred while trying to start recording. Please check your microphone permissions and try again.");
      }
    },
    
    startRecordingTimer() {
      this.recordingIntervalId = setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - this.recordingStartTime) / 1000);
        const minutes = Math.floor(elapsedSeconds / 60);
        const seconds = elapsedSeconds % 60;
        this.recordingTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }, 1000);
    },
    
    stopRecordingTimer() {
      if (this.recordingIntervalId) {
        clearInterval(this.recordingIntervalId);
        this.recordingIntervalId = null;
      }
    },
    
    drawWaveform() {
      if (!this.canvasContext && this.$refs.canvas) {
        this.canvasContext = this.$refs.canvas.getContext('2d');
      }
      
      const draw = () => {
        this.animationFrameId = requestAnimationFrame(draw);
        if (!this.analyser || !this.dataArray || !this.canvasContext || !this.$refs.canvas) return;
        this.analyser.getByteTimeDomainData(this.dataArray);
        this.canvasContext.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        this.canvasContext.lineWidth = 3;
        this.canvasContext.strokeStyle = '#00695C';
        this.canvasContext.beginPath();
        const sliceWidth = this.$refs.canvas.width * 1.0 / this.bufferLength;
        let x = 0;
        for (let i = 0; i < this.bufferLength; i++) {
          const v = this.dataArray[i] / 128.0;
          const y = v * this.$refs.canvas.height / 2;
          if (i === 0)
            this.canvasContext.moveTo(x, y);
          else
            this.canvasContext.lineTo(x, y);
          x += sliceWidth;
        }
        this.canvasContext.lineTo(this.$refs.canvas.width, this.$refs.canvas.height / 2);
        this.canvasContext.stroke();
      };
      
      draw();
    },
    
    async fetchBlob(url) {
      const response = await fetch(url);
      return await response.blob();
    },
    
    async sendMessage() {
      
      if (this.showNewInformationRequest && !this.$refs.informationRequest.request.fields.length)
        return this.$snack.error('At least one form request field is required')
      
      if (this.isRecording) {
        this.sendAfterStop = true
        return this.stopRecording()
      }
      
      try {
        this.sendingMessage = true
        const sendBody = new OutboxMessage({chatKey: this.chatKey, message: this.messageText})
        const formData = new FormData();
        formData.append('chatKey', sendBody.chatKey);
        formData.append('refId', sendBody.refId);
        if (sendBody.message)
          formData.append('message', sendBody.message);
        if (this.audioUrl) {
          formData.append('attachments', await this.fetchBlob(this.audioUrl));
        }
        
        if (this.viewImages && this.viewImages.length) {
          const blobs = await Promise.all(this.viewImages.map(url => this.fetchBlob(url)))
          for (const blob of blobs) {
            formData.append('attachments', blob);
          }
        }
        
        if (this.viewFiles && this.viewFiles.length) {
          for (const file of this.viewFiles) {
            formData.append('attachments', file.file);
          }
        }
        
        if (this.viewTaskDocuments.length)
          formData.append('taskAttachments', JSON.stringify(this.viewTaskDocuments));
        
        if (this.showNewInformationRequest)
          formData.append('informationRequest', JSON.stringify(this.$refs.informationRequest.request));
        
        const userMentions = []
        const contactMentions = []
        
        for (const member of this.chatAccess) {
          if ((sendBody?.message || '').includes(`@${member.name} `)) {
            if (member.type === 'user')
              userMentions.push(member.id)
            else
              contactMentions.push(member.id)
          }
        }
        
        
        
        if (userMentions.length)
          formData.append('userMentions', JSON.stringify(userMentions))
        if (contactMentions.length)
          formData.append('contactMentions', JSON.stringify(contactMentions))
        
        const res = await this.$newReq('POST', 'chat/sendMessage', formData);
        this.audioUrl = null
        this.messageText = null
        this.viewFiles = [];
        this.viewImages = [];
        this.viewTaskDocuments = [];
        this.showNewInformationRequest = false;
        this.informationRequestTemplate = null;
        setTimeout(() => {
          this.$refs.messageTextArea.focus()
        }, 200)
        this.$emit('messageSent');
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.sendingMessage = false;
      }
    },
    
    selectEmoji(e) {
      
      const textarea = this.$refs.messageTextArea.$refs.input;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      
      this.messageText = this.messageText || ''
      
      this.messageText = this.messageText.substring(0, start) + e.val + this.messageText.substring(end);
      
      setTimeout(() => {
        textarea.focus()
        setTimeout(() => {
          textarea.selectionStart = textarea.selectionEnd = start + e.val.length;
        }, 50)
        
        // this.showEmojiMenu = false
      }, 50)
    },
  },
  
  computed: {
    ...mapGetters('userInfo', ['userInfo']),
    allowSend() {
      return this.messageText || this.isRecording || this.audioUrl || this.viewFiles.length || this.viewImages.length || this.viewTaskDocuments.length || this.showNewInformationRequest
    },
    
    sortedAccess() {
      return this.chatAccess
          .filter((v, i, a) => a.findIndex(av => av.name === v.name) === i)
          .sort((a,b) => a.name > b.name ? 1 : -1)
    },
    
    filteredAccess() {
      return this.sortedAccess
          .filter(v => !this.mentionSearch ? true : v.name.substring(0, this.mentionSearch.length).toLowerCase() === this.mentionSearch.toLowerCase())
    }
  },
}
</script>

<style scoped>
input[type="file"] {
  display: none;
}
</style>