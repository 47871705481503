<template>
  <div>
    <input-dlg
        v-if="showDialog"
        :show-dialog="showDialog"
        title="Auto Read Rules"
        :cancel-button="true"
        :show-input-box="false"
        @dismiss="showDialog = false"
        :body-padding="false"
        :width="850"
    >
      <template slot="body">
        <div v-if="loading" class="pa-3 c-d-flex c-align-center c-justify-center">
          <v-progress-circular
              :size="40"
              :width="5"
              color="primary"
              indeterminate
          ></v-progress-circular>
        </div>
        <div v-else class="py-1">
          
          <table style="width: 100%; overflow-x: hidden; border-collapse: collapse"
                 class="body-2 font-weight-regular rule-table">
            <thead>
            <tr>
              <td colspan="3" class="py-2 font-weight-bold">
                Auto read chat messages if:
              </td>
            </tr>
            </thead>
            <tr v-if="!rules.length">
              <td colspan="5" class="py-2 text-xs-center caption">
                No rules have been added
              </td>
            </tr>
            <template v-for="rule in entityRules.toSorted((a,b) => (a.fkWorkflowId || 0) - (b.fkWorkflowId || 0))">
              <tr class="hover-item" >
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap">
                  <div style="position: relative">
                    <sup style="position: absolute; left: 0; top: 0; line-height: 1; color: var(--on-surface-grey); transform: translateY(-100%);" class="pt-1">
                      Entity Default
                    </sup>
                  </div>
                  Workflow =
                  <div style="position: relative">
                    <sup style="position: absolute; left: 0; bottom: 0; transform: translateY(100%); line-height: 1; color: var(--on-surface-grey)" class="pt-1">
                      Auto read all task messages on {{ !rule.fkWorkflowId ? 'All Workflows' : (workflowsMap[rule.fkWorkflowId]?.description || `Workflow ${rule.fkWorkflowId}`)}}
                      <template v-if="!rule.isNotOwner && !rule.isNotMentioned">
                        regardless
                      </template>
                      <template v-else-if="rule.isNotOwner && !rule.isNotMentioned">
                        if I am not the owner regardless of if I was mentioned or not
                      </template>
                      <template v-else-if="rule.isNotOwner && rule.isNotMentioned">
                        if I am not the owner or I was not mentioned
                      </template>
                    </sup>
                  </div>
                </td>
                <td class="font-weight-bold" style="color: var(--on-surface-grey);">
                  {{ !rule.fkWorkflowId ? 'All Workflows' : (workflowsMap[rule.fkWorkflowId]?.description || `Workflow ${rule.fkWorkflowId}`)}}
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-right: 0 !important;">and (</td>
                <td>
                  <div class="c-d-flex c-justify-center c-align-center">
                    <v-checkbox
                        :disabled="true"
                        v-model="rule.isNotOwner"
                        color="secondary"
                        class="pt-0 mt-0 c-flex-grow-0"
                        :hide-details="true"
                        label="Someone else's task"
                        style="white-space: nowrap !important;"
                    />
                  </div>
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-right: 0 !important; padding-left: 0 !important;">or</td>
                <td>
                  <div class="c-d-flex c-justify-center c-align-center">
                    <v-checkbox
                        :disabled="true"
                        v-model="rule.isNotMentioned"
                        color="secondary"
                        class="pt-0 mt-0 c-flex-grow-0"
                        :hide-details="true"
                        label="I was not mentioned"
                        style="white-space: nowrap !important;"
                    />
                  </div>
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-left: 0 !important;">)</td>
                <td class="py-2">
                  <v-btn
                      :disabled="true"
                      @click="rules = rules.filter(v => v.fkWorkflowId !== rule.fkWorkflowId)"
                      flat
                      icon
                      color="error"
                      small
                      class="px-0 mx-0"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            
            <template v-for="rule in rules.toSorted((a,b) => (a.fkWorkflowId || 0) - (b.fkWorkflowId || 0))">
              <tr class="hover-item" >
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap">
                  Workflow =
                  <div style="position: relative">
                    <sup style="position: absolute; left: 0; bottom: 0; transform: translateY(100%); line-height: 1; color: var(--on-surface)" class="pt-1">
                      Auto read all task messages on {{ !rule.fkWorkflowId ? 'All Workflows' : (workflowsMap[rule.fkWorkflowId]?.description || `Workflow ${rule.fkWorkflowId}`)}}
                      <template v-if="!rule.isNotOwner && !rule.isNotMentioned">
                        regardless
                      </template>
                      <template v-else-if="rule.isNotOwner && !rule.isNotMentioned">
                        if I am not the owner regardless of if I was mentioned or not
                      </template>
                      <template v-else-if="!rule.isNotOwner && rule.isNotMentioned">
                        if I was not mentioned regardless of if I am not the owner or not
                      </template>
                      <template v-else-if="rule.isNotOwner && rule.isNotMentioned">
                        if I am not the owner or I was not mentioned
                      </template>
                    </sup>
                  </div>
                </td>
                <td class="font-weight-bold secondary--text">
                  {{ !rule.fkWorkflowId ? 'All Workflows' : (workflowsMap[rule.fkWorkflowId]?.description || `Workflow ${rule.fkWorkflowId}`)}}
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-right: 0 !important;">and (</td>
                <td>
                  <div class="c-d-flex c-justify-center c-align-center">
                    <v-checkbox
                        v-model="rule.isNotOwner"
                        color="secondary"
                        class="pt-0 mt-0 c-flex-grow-0"
                        :hide-details="true"
                        label="Someone else's task"
                        style="white-space: nowrap !important;"
                    />
                  </div>
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-right: 0 !important; padding-left: 0 !important;">or</td>
                <td>
                  <div class="c-d-flex c-justify-center c-align-center">
                    <v-checkbox
                        v-model="rule.isNotMentioned"
                        color="secondary"
                        class="pt-0 mt-0 c-flex-grow-0"
                        :hide-details="true"
                        label="I was not mentioned"
                        style="white-space: nowrap !important;"
                    />
                  </div>
                </td>
                <td class="body-2" style="color: var(--on-surface-grey); white-space: nowrap; padding-left: 0 !important;">)</td>
                <td class="py-2">
                  <v-btn
                      @click="rules = rules.filter(v => v.fkWorkflowId !== rule.fkWorkflowId)"
                      flat
                      icon
                      color="error"
                      small
                      class="px-0 mx-0"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            
          </table>
          <div class="c-d-flex c-justify-end">
            <v-btn small flat color="secondary" @click="showWorkflowSelect = true">Add Rule</v-btn>
          </div>
        </div>
      </template>
      <template slot="actions">
        <v-btn
            small
            color="secondary"
            class="py-0 px-2 ma-0"
            @click="saveChanges()"
        >Save changes
        </v-btn>
      </template>
    </input-dlg>
    <input-dlg
        v-if="showWorkflowSelect"
        :show-dialog="showWorkflowSelect"
        title="Select Workflow"
        :cancel-button="true"
        :show-input-box="false"
        @dismiss="showWorkflowSelect = false"
        :body-padding="false"
    >
      <template #body>
        <div style="max-height: 75vh" class="of-y">
          <v-treeview
              class="pt-2 pl-2"
              color="primary"
              :items="sortedWorkflows"
              item-children="children"
              item-text="description"
              item-key="key"
              dense
              compact
              hide-details
              open-on-click
              hoverable
          >
            <template #label="{item}">
              <div class="c-d-flex c-align-center" @click="item.allowSelect ? workflowSelected(item) : () => {}">
                <v-icon v-if="item.allowSelect">check_box_outline_blank</v-icon>
                {{item.description}}
              </div>
            </template>
          </v-treeview>
        </div>
      </template>
    </input-dlg>
  </div>
</template>

<script>
import InputDlg from "@/components/General/InputDlg.vue";
import {objectifyArr} from "@/codeFunctions";

export default {
  components: {InputDlg},
  props: {
    shown: Boolean
  },
  data() {
    return {
      loading: false,
      workspaces: [],
      workflowGroups: [],
      workflows: [],
      rules: [],
      entityRules: [],
      showWorkflowSelect: false
    }
  },
  watch: {
    shown: {
      immediate: true,
      handler(val) {
        if (val)
          this.init()
      }
    }
  },
  methods: {
    async saveChanges() {
      try {
        this.loading = true
        const {workspaces, workflowGroups, workflows, rules} = await this.$newReq('POST', `chat/autoReadUpdate`, this.rules)
        this.workspaces = workspaces;
        this.workflowGroups = workflowGroups;
        this.workflows = workflows;
        this.rules = rules.filter(v => v.fkUserId);
        this.entityRules = rules.filter(v => !v.fkUserId)
        this.$snack.info('Changes Saved')
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    async init() {
      try {
        this.loading = true
        const {workspaces, workflowGroups, workflows, rules} = await this.$newReq('POST', `chat/autoReadStartup`)
        this.workspaces = workspaces;
        this.workflowGroups = workflowGroups;
        this.workflows = workflows;
        this.rules = rules.filter(v => v.fkUserId);
        this.entityRules = rules.filter(v => !v.fkUserId)
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.loading = false
      }
    },
    workflowSelected(workflow) {
      if (!this.rules.find(v => v.fkWorkflowId === workflow.id))
        this.rules.push({
          isNotOwner: false,
          isNotMentioned: false,
          fkWorkflowId: workflow.id
        })
      
      this.showWorkflowSelect = false
    }
  },
  computed: {
    workflowsMap() {
      return objectifyArr(this.workflows, 'id')
    },
    showDialog: {
      get() {
        return this.shown
      },
      set(val) {
        this.$emit('update:shown', val)
      }
    },
    sortedWorkflows() {
      return [
        {
          id: null,
          key: 0,
          allowSelect: true,
          description: 'Any Workflow'
        },
        ...this.workspaces
            .reduce((workspaces, workspace) => [
              ...workspaces,
              {
                id: workspace.id,
                key: `ws${workspace.id}`,
                description: workspace.description,
                children: this.workflowGroups
                    .filter(v => v.fk_Workspace === workspace.id)
                    .reduce((workflowGroups, workflowGroup) => [
                      ...workflowGroups,
                      {
                        id: workflowGroup.id,
                        key: `wg${workflowGroup.id}`,
                        description: workflowGroup.description,
                        children: this.workflows
                            .filter(wf => wf.fk_workflow_group === workflowGroup.id)
                            .map(wf => ({...wf, key: wf.id, allowSelect: true}))
                            .toSorted((a, b) => a.name > b.name ? 1 : -1)
                      }
                    ], [])
                    .toSorted((a, b) => a.name > b.name ? 1 : -1)
              }], [])
            .toSorted((a, b) => a.name > b.name ? 1 : -1)
      ]
    }
  }
}
</script>


<style scoped>
.rule-table td {
  padding-left: 8px;
  padding-right: 8px;
}
</style>