import format from 'date-fns/format';
import {MailParser} from "mailparser-mit";
import {getColor} from "@calvin-coomer/avatar-color-picker";
const xls = require('json-as-xlsx');

function exportStats(filename, sheetName, headers, srcData, labelsRef) {
    const columns = headers
        .map(col => {
            return {
                label: col.text,
                value: col.value,
            }
        })
        .filter(obj => obj.value !== 'actions')

    const content = [...srcData]
    let data = [{
        sheet: sheetName,
        columns: columns,
        content: content
    }]
    let settings = {
        fileName: filename,
    }

    xls(data, settings)
}

function arrSplice(array, spliceOperator) {
    for (let i = 0; i < array.length; i++) {
        let res = spliceOperator(array[i]);
        if (typeof res == 'boolean' && res) {
            array.splice(i, 1);
            return arrSplice(array, spliceOperator);
        }
    }
    return array
}

function stringifyHtml(value) {
    return value
        .replace(/"/g, '~&QOTE')
        .replace(/\n/g, '~&NEW')
        .replace(/\r/g, '~&ROW')
        .replace(/\t/g, '~&TAB')
}

function parseHtml(value) {
    return value
        .replace(/~&QOTE/g, '"')
        .replace(/~&NEW/g, '\n')
        .replace(/~&ROW/g, '\r')
        .replace(/~&TAB/g, '\t')
}

function arrPopulated(array) {
    return Array.isArray(array) && array.length > 0;
}

function blankString(value) {
    return typeof value === "undefined" || value == null || value === '';
}

function getUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

function objectifyArr(arr, identifier) {
    return arr.reduce((obj, item) => {
        try {
            obj[item[identifier]] = item
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        }
        return obj
    }, {})
}

const copyText = (documentContext, text) => {
    const node = documentContext.createElement("input");
    node.setAttribute('id', 'copyInput')
    node.setAttribute('value', text)
    documentContext.body.appendChild(node);
    const inputBox = documentContext.getElementById('copyInput');
    inputBox.select();
    documentContext.execCommand("copy");
    navigator.clipboard.writeText(inputBox.value)
    documentContext.body.removeChild(node);
}

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function validateCellNo(number) {
    var re = /(^0\d\d\d\d\d\d\d\d\d$)|(^27\d\d\d\d\d\d\d\d\d$)|(^(\+1)?[2-9]\d{2}[2-9](?!11)\d{6}$)/;
    return re.test(number);
}

function getDateTimeSpaceSeperated(date) {
    return format(date, "YYYY-MM-DD HH-mm")
}

function getShortDate(date) {
    return format(date, "D MMM YY")
}

function getLongDate(date) {
    return format(date, "D MMMM YYYY")
}

function getTime(date) {
    return format(date, "HH:mm")
}

function arrPushMerge(arr, vals, compareKey) {
    vals.forEach(val => {
        let bExists = arr.reduce((exists, obj) => {
            if (!exists) {
                if (blankString(compareKey)) {
                    if (val === obj) {return true}
                } else {
                    if (val[compareKey] === obj[compareKey]) {return true}
                }
            }
            return exists
        }, false);
        if (!bExists) {arr.push(val)}
    });
    return arr;
}

function boolToInt(boolVal) {
    if (boolVal) {
        return 1
    } else {
        return 0
    }
}

function intToBool(intVal) {
    if (intVal == 1) {
        return true
    } else {
        return false
    }
}


function parseMail(mailStr) {
    return new Promise(async (resolve, reject) => {
        try {
            let mailParser = new MailParser();
            mailParser.on("end", mailData => {
                if (!mailData.attachments)
                    mailData.attachments = []

                mailData.attachments.forEach(att => {
                    let replaceSrcStr = new RegExp(`cid:${att.contentId}`, "gi");

                    let match = (mailData.html || 'NO BODY').match(replaceSrcStr)
                    if (!!match && match.length) {
                        att.contentDisposition = 'inline';
                        let replaceSrcDest = `data:${att.contentType};base64,${att.content.toString('base64')}`;
                        mailData.html = mailData.html.replace(replaceSrcStr, replaceSrcDest);
                    }
                })
                resolve(mailData)
            });
            mailParser.on("error", err => {
                reject(err)
            });
            mailParser.write(mailStr);
            mailParser.end();

        } catch (e) {
            reject(e)
        }
    })
}

function getInitials(str) {
    if (!str)
        return null

    return [str?.trim().split(' ').shift(), str?.trim().split(' ').pop()]
      .filter((v, i, a) => a.indexOf(v) === i)
      .map(v => v?.split('')?.shift())
      .join('')
      .toUpperCase();
}

const validationRules = {
    required: value => !!value || 'Required Value',
    notZero: value => value === '0' || value === '' || value == null ? 'Value can not be zero' : true,
    notNull: value => value !== null || 'Required Value',
    min6Characters: value => value !== null && value.split('').length >= 6 || 'Minimum 6 characters',
    specialCharacter: value => value !== null && /[^A-Za-z0-9]/.test(value) || 'Minimum 1 special character (eg. !@#$%)',
    upperCase: value => value !== null && /[A-Z]/.test(value) || 'Minimum 1 uppercase character',
    lowerCase: value => value !== null && /[a-z]/.test(value) || 'Minimum 1 lowercase character',
    numeric: value => value !== null && /[0-9]+/.test(value) || 'Minimum 1 numerical character',
    numberOnly: value => value !== null && /^[\d\\.]+$/.test(value) || 'Only numbers allowed',
    wholeNumberOnly: value => value !== null && /^[\d]+$/.test(value) || 'Only whole numbers allowed',
    email: value => {
        if (value && value !== null && value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Invalid Email Address';
        } else {
            return 'Please enter a valid email address'
        }
    },
    emailNotRequired: value => {

        if (!value)
            return true

        if (value && value.length > 0) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || 'Invalid Email Address';
        } else {
            return 'Please enter a valid email address'
        }
    },
    cellNo: value => value !== null && (/[0-9+ ][^A-Za-z]{9,}/.test(value) || /^(\+1)?[2-9]\d{2}[2-9](?!11)\d{6}$/.test(value)) || 'Please enter a valid phone number',
    cellNoNotRequired: value => !value || (value !== null && (/[0-9+ ][^A-Za-z]{9,}/.test(value) || /^(\+1)?[2-9]\d{2}[2-9](?!11)\d{6}$/.test(value))) || 'Please enter a valid phone number',
}

const nameSort = (a,b) => a.name > b.name ? 1 : -1;
const nameSortDesc = (a,b) => a.name > b.name ? 1 : -1;
const descriptionSort = (a,b) => a.description > b.description ? 1 : -1;
const descriptionSortDesc = (a,b) => a.description > b.description ? 1 : -1;
const keySort = (key, a, b) => a[key] > b[key] ? 1 : -1;
const keySortDesc = (key, a, b) => a[key] < b[key] ? 1 : -1;

const sorter = (arr, fn) => {
    try {
        return [...arr].sort(fn)
    } catch (e) {
        console.log(e)
        return null
    }
}

const colors = {
    "deep-orange": "#FF5722",
        "brown": "#795548",
        "blue-grey": "#607D8B",
        "orange": "#FF9800",
        "amber": "#FFC107",
        "yellow": "#FFEB3B",
        "green": "#4CAF50",
        "light-green": "#8BC34A",
        "lime": "#CDDC39",
        "teal": "#009688",
        "cyan": "#00BCD4",
        "light-blue": "#03A9F4",
        "deep-purple": "#673AB7",
        "indigo": "#3F51B5",
        "blue": "#2196F3",
        "red": "#F44336",
        "pink": "#E91E63",
        "purple": "#9C27B0",
}

export function getInitialsAndColor(name) {
    return {
        initials: [name?.trim().split(' ').shift(), name?.trim().split(' ').pop()]
          .filter((v, i, a) => a.indexOf(v) === i)
          .map(v => v?.split('')?.shift())
          .join('')
          .toUpperCase() || '',
        color: getColor(name || '')
    }
}


export {
    arrSplice,
    arrPopulated,
    blankString,
    getUUID,
    objectifyArr,
    validateEmail,
    validateCellNo,
    getShortDate,
    getLongDate,
    getDateTimeSpaceSeperated,
    arrPushMerge,
    intToBool,
    boolToInt,
    parseMail,
    getInitials,
    validationRules,
    colors,
    stringifyHtml,
    parseHtml,
    copyText,
    nameSort,
    nameSortDesc,
    descriptionSort,
    descriptionSortDesc,
    keySort,
    keySortDesc,
    sorter,
    exportStats,
    getTime
}
