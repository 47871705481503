<template>
    <div :class="messageData ? 'print-view' : 'print-view-hidden'" @click="messageData = null">
        <e-m-l-viewer
                ref="emlView"
                :printing="true"
                @ready="printMail"
        />
    </div>
</template>

<script>

import {createMailEvents} from "./createMailManager";
import EMLViewer from "../EML Viewer/EMLViewer";

export default {
    components: {EMLViewer},
    props: {},
    data() {
        return {
            messageData: null
        }
    },
    created() {
        createMailEvents.on('printMail', (messageData) => {
            this.messageData = messageData;
            this.$nextTick(() => {
                this.$emit('print', true)
                this.$refs.emlView.inception(messageData)
            })
        })
    },
    methods: {
        printMail() {
            this.$refs.emlView.printMailRun()
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    this.messageData = null;
                    this.$emit('print', false)
                })
        }
    },
    computed: {


    },
}
</script>

<style scoped>
    .print-view-hidden {
        display: none;
    }

    .print-view {
        position: absolute;
        top: 0;
        left: 0;
        min-width: 100vw;
        min-height: 100vh;
        width: 100%;
        height: 100%;
        z-index: 999999;
        background: white;
        display: block;
    }
</style>