<template>
    <div class="fill-height">

        <div class="c-d-flex c-align-center c-justify-center fill-height" v-if="loadingFields">
            <v-progress-circular
                    :size="40"
                    :width="5"
                    color="primary"
                    indeterminate
            ></v-progress-circular>
        </div>

                                <div
                                        v-for="field in ungroupedFields"
                                        :key="'FieldSetField' + field.fieldID"
                                >
                                    <template v-if="field.type !== 16">
                                        <div class="px-1">
                                            <field-set-field
                                                    v-if="!hiddenFields.includes(field.fieldID)"
                                                    :field="field"
                                                    :user-info="userInfo"
                                                    :readOnly="softBlock ? true : !!selectedDataset.readOnly"

                                                    :folderDocs="documents"
                                                    :folderMails="mails"

                                                    :read-only-field="readOnlyFields.includes(field.fieldID)"
                                                    :secure-field="secureFields.includes(field.fieldID)"
                                                    :add-doc-to-task-btn="addDocToTaskBtn"
                                                    @forceSave="$emit('forceSave')"
                                                    @addDocToTask="$emit('addDocToTask', $event)"
                                            />
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div>
                                            <v-layout row align-center
                                                      @click="droppedGroups.includes(field.fieldID) ? droppedGroups = droppedGroups.filter(obj => obj !== field.fieldID) : droppedGroups.push(field.fieldID)"
                                                      class="primary"
                                            >
                                                <v-flex xs12 class="py-2 pl-2 white--text caption" style="border-radius: 3px">
                                                    {{field.fieldDescription}}
                                                </v-flex>
                                                <v-flex shrink class="pr-2">
                                                    <v-btn
                                                            small
                                                            icon
                                                            flat
                                                            class="pa-0 ma-0"
                                                            color="white"
                                                    >
                                                        <v-icon>{{droppedGroups.includes(field.fieldID) ? 'arrow_drop_up' : 'arrow_drop_down'}}</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                            <v-divider color="white"></v-divider>
                                            <template v-if="droppedGroups.includes(field.fieldID)">
                                                <div
                                                        class="px-1"
                                                        v-for="subField in groupFields[field.fieldID]"
                                                        :key="'FieldSetField' + subField.fieldID"
                                                >
                                                    <field-set-field
                                                            v-if="!hiddenFields.includes(subField.fieldID)"
                                                            :field="subField"
                                                            :user-info="userInfo"
                                                            :readOnly="!!selectedDataset.readOnly"
                                                            :folderDocs="documents"
                                                            @forceSave="$emit('forceSave')"
                                                            :folderMails="mails"
                                                            :read-only-field="readOnlyFields.includes(subField.fieldID)"
                                                            :secure-field="secureFields.includes(subField.fieldID)"
                                                            :add-doc-to-task-btn="addDocToTaskBtn"
                                                            @addDocToTask="$emit('addDocToTask', $event)"
                                                    />
                                                </div>
                                                <v-divider></v-divider>
                                            </template>
                                        </div>

                                    </template>

                                </div>

        <div style="position: fixed; bottom: 15px; right: 15px;">

            <v-tooltip left v-if="folderAccess">
                <v-btn
                        dark
                        fab
                        slot="activator"
                        color="secondary"
                        v-if="saveFields.length > 0"
                        @click="saveChanges"
                        :loading="savingChanges"
                        :disabled="savingChanges"
                >
                    <v-icon>save</v-icon>
                </v-btn>
                <span>Save Changes</span>
            </v-tooltip>

            <slot>

            </slot>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import FieldSetField from "@/Folders/components/FieldSetField";
import {eventBus} from "@/main";

export default {
    components: {FieldSetField},
    props: {
        selectedDataset: Object,
        addDocToTaskBtn: {
            type: Boolean,
            default: false
        },
        folderAccess: Boolean,
        folderId: Number,
        fieldSets: Array,
    },
    data() {
        return {
            loadingFields: false,
            datasetFields: [],
            mails: [],
            documents: [],
            savingChanges: false,
            droppedGroups: [],
        }
    },
    watch: {
        selectedDataset: {
            immediate: true,
            handler() {
                this.datasetFields = [];
                this.mails = [];
                this.documents = [];
                this.resetDataset();
            }
        },
        selectLists: Array,
    },
    methods: {
        saveChanges() {
            // Check Required Fields
            let bSaveReady = true;

            this.datasetFields.forEach(field => {
                field.saveStatus = 0;
                field.saveMessage = '';
                if (field.required) {

                    if (field.oldValue == null && field.oldValue == '') {
                        if (!this.isSaveReady(field)) {
                            bSaveReady = false;
                            field.saveStatus = 1;
                            field.saveMessage = 'This Field Is Required';
                        }
                    }
                }
            });

            if (!bSaveReady) {
                this.showSnack('Error', 'Complete All Required Fields', 'Close', 'red')
            } else {
                this.savingChanges = true;
                this.$newReq('POST', `fieldsets/saveChanges/${this.userInfo.entityID}/${this.userInfo.userID}/${this.selectedDataset.memberID}/${this.folderId}`, this.saveFields)
                        .then((data) => {
                            this.datasetFields.forEach((FSF, i) => {
                                data.forEach((FSFUD) => {
                                    if (FSF.fieldSetMember == FSFUD.fieldSetMember && FSF.fieldID == FSFUD.fieldID) {
                                        this.datasetFields.splice(i, 1, FSFUD)
                                    }
                                })
                            });
                            this.savingChanges = false;
                        })
                        .catch((e) => {
                            // eslint-disable-next-line
                            console.log(e);
                            this.savingChanges = false;
                        })

                let datasetDisplayNames = [...this.datasetFields].sort((a,b) => a.order - b.order).reduce((obj, field) => {
                    if (!obj[field.fieldSetMember])
                        obj[field.fieldSetMember] = [];
                    obj[field.fieldSetMember] = field.useInName ? obj[field.fieldSetMember].concat(field.value) : obj[field.fieldSetMember]
                    return obj
                }, {});

                for (const key of Object.keys(datasetDisplayNames)) {
                    datasetDisplayNames[key] = datasetDisplayNames[key].filter(str => !!str).join(' ')
                }

                this.fieldSets.forEach(fs => {
                    if (fs.memberID === this.selectedDataset.memberID)
                        fs.valueName = datasetDisplayNames[fs.memberID];
                })

            }
        },

        isSaveReady(field) {
            if (field.value != null && field.value != '') {
                if (field.value != field.oldValue) {
                    return true
                } else {
                    return false
                }
            }
        },
        resetDataset() {

            this.loadingFields = true;
            this.$newReq('GET', `fieldsets/folderDatasetMemberDetail/${this.userInfo.entityID}/${this.selectedDataset.memberID}`)
                    .then(({datasetFields, mails, documents}) => {
                        this.datasetFields = datasetFields;
                        this.mails = mails;
                        this.documents = documents;
                    })
                    .catch(e => {
                        console.log(e);
                        this.$snack.networkError();
                    })
                    .finally(() => {
                        this.loadingFields = false;


                        // this.checkChanges();
                        this.droppedGroups = [];
                        this.datasetFields
                                .filter(field => field.type === 16 && field.lookup == '1')
                                .forEach(field => {
                                    if (!this.droppedGroups.includes(field.fieldID))
                                        this.droppedGroups.push(field.fieldID)
                                })

                    })
        },

        // checkChanges() {
        //     if (this.folder != null && this.selectedSet != {} && this.selectedSet != null) {
        //         this.droppedGroups = [];
        //     }
        //     if (this.folder != null && this.selectedSet != {} && this.selectedSet != null && this.lastAskID != this.selectedSet.memberID && this.saveFields.length > 0) {
        //         this.lastAskID = this.selectedSet.memberID;
        //         eventBus.$emit('DatasetDismissedWithChanges', {
        //             dataSetMemberID: this.selectedSet.memberID,
        //             dataSetDescription: this.selectedSet.fieldSetName,
        //             saveFields: this.saveFields.filter(() => true),
        //             folderName: this.folder.name,
        //             folderID: this.folder.id
        //         })
        //     }
        // },

        isJson(stringValue, callback, defaultValue, ...conditionals) {
            try {
                if (conditionals.includes(false)) {return typeof defaultValue == 'undefined' ? null : defaultValue = null}
                let tObj = JSON.parse(stringValue);
                return typeof callback == 'undefined' ? tObj : callback(tObj);
            } catch (e) {
                return typeof defaultValue == 'undefined' ? null : defaultValue = null;
            }
        },
    },
    computed: {
        ...mapGetters('userInfo', ["userInfo", "softBlock"]),
        groupFields() {
            return this.datasetFields.reduce((obj, field) => {
                if (typeof field.fk_field_group != 'undefined' && field.fk_field_group != null && field.fk_field_group !== 0) {
                    if (typeof obj[field.fk_field_group] == 'undefined') {
                        obj[field.fk_field_group] = [];
                    }
                    obj[field.fk_field_group].push(field);
                    obj[field.fk_field_group].sort((a,b) => a.order-b.order)
                }
                return obj;
            }, {});
        },
        saveFields() {
            let tFields = [];
            this.datasetFields.forEach((field) => {
                if (field.value != null && field.value != '') {
                    if (field.value != field.oldValue) {
                        tFields.push(field)
                    }
                }
            });
            this.$emit('changesMade', tFields.length > 0)
            return tFields;
        },

        ungroupedFields() {
            return this.datasetFields.filter(obj => obj.fk_field_group == null).sort((a,b) => a.order - b.order)
        },
        hiddenFields() {
            let tValues = this.datasetFields.reduce((hiddenFields, field) => {
                this.isJson(field.securityLookup, (securityLookup) => {
                    if (
                            Array.isArray(securityLookup.shownTo)
                            && securityLookup.shownTo.length > 0
                            && securityLookup.shownTo.filter(obj => this.userInfo.userGroups.includes(obj)).length <= 0
                    ) {
                        hiddenFields.push(field.fieldID)
                    }
                });
                return hiddenFields;
            }, []);
            return tValues
        },
        readOnlyFields() {
            let tValues = this.datasetFields.reduce((readOnlyFields, field) => {

                this.isJson(field.securityLookup, (securityLookup) => {
                    if (
                            Array.isArray(securityLookup.editableBy)
                            && securityLookup.editableBy.length > 0
                            && securityLookup.editableBy.filter(obj => this.userInfo.userGroups.includes(obj)).length <= 0
                    ) {
                        readOnlyFields.push(field.fieldID)
                    }
                });

                return readOnlyFields;
            }, []);
            return tValues
        },
        secureFields() {
            let tValues = this.datasetFields.reduce((fields, field) => {

                this.isJson(field.securityLookup, (securityLookup) => {
                    if (
                            (Array.isArray(securityLookup.editableBy) && securityLookup.editableBy.length > 0) || (Array.isArray(securityLookup.shownTo) && securityLookup.shownTo.length > 0)) {
                        fields.push(field.fieldID)
                    }
                });

                return fields;
            }, []);
            return tValues
        },
    },
}
</script>

<style scoped>

</style>