<template>
    <div>
        <div @click="!disabled ? showAdd = !showAdd : ''">
            <slot name="activator"></slot>
        </div>

        <app-input-dlg
                v-if="showAdd"
                :show-dialog="showAdd"
                title="Add User"
                :cancel-button="true"
                :show-input-box="false"
                @dismiss="showAdd = false"
                :body-padding="false"
        >
            <template slot="body">
                <div class="px-1 pt-1">
                    <div :class="topicClass">
                        <div :class="headingClass">
                            Select User to Add:
                        </div>
                    </div>
                    <v-text-field
                            label="Filter Users"
                            single-line
                            color="primary"
                            hide-details
                            style="width: 100%"
                            class="pa-0 ma-0"
                            ref="UFilter"
                            v-model="usersFilterString"
                            prepend-inner-icon="search"
                    />
                    <div class="scrollable" style="height: 300px">
                        <div v-for="(item, n) in filteredSelectedUsers" :key="n" >
                            <div
                                    v-if="typeof item != 'undefined'"
                                    class="hover-item"
                                    :class="selectedUser != null && selectedUser.id == item.id ? 'selected-item' : 'selected-item'"
                            >
                                <v-layout row align-center style="overflow-x: hidden" class="hide-parent">
                                    <v-flex xs12 style="overflow-x: hidden" class="pa-2" @click="selectedUser = item">
                                        <div class="body-1" :class="selectedUser != null && selectedUser.id == item.id ? 'primary--text' : (intToBool(item.disabled) ? 'greyType--text' : '')">
                                            {{`${item.description}${intToBool(item.disabled) ? ' (Disabled)' : ''}`}}
                                        </div>
                                        <div class="caption text-truncate greyType--text"  style="overflow-x: hidden">
                                            {{item.username}}
                                        </div>
                                    </v-flex>
                                </v-layout>
                                <v-divider/>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="actions">
                <div class="text-xs-right">
                    <v-btn
                            small
                            color="secondary"
                            :disabled="selectedUser == null || addingUser"
                            :loading="addingUser"
                            @click="addUser"
                    >
                        Add User
                    </v-btn>
                </div>
            </template>
        </app-input-dlg>
    </div>
</template>

<script>
    import {codeBaseMixin} from "../../../../../codeBaseMixin";
    import InputDlg from "../../../../../components/General/InputDlg";

    export default {
        components: {
            appInputDlg: InputDlg
        },
        mixins: [codeBaseMixin],
        props: {
            userInfo: Object,
            selectedUserGroup: Object,
            users: Array,
            disabled: Boolean
        },
        data() {
            return {
                showAdd: false,
                usersFilterString: null,
                selectedUser: null,
                addingUser: false,
            }
        },
        watch: {
            showAdd(shown) {
                if (shown) {
                    this.usersFilterString = null;
                    this.selectedUser = null;
                    this.addingUser = false;
                    this.$nextTick(() => {
                        this.$refs.UFilter.focus();
                    })
                }
            },
        },
        methods: {
            addUser() {
                this.addingUser = true;
                this.fetch({method: 'POST', url:`/workflowManager/security/user/addMember/${this.userInfo.entityID}/${this.selectedUserGroup.id}/${this.selectedUser.id}`})
                    .then(() => {

                        this.$emit('userAdded', this.selectedUser);
                        this.showAdd = false;
                        this.addingUser = false;
                        this.showSnack('Info', 'User Added', 'Close', 'primary')
                    })
                    .catch((e) => {
                        // eslint-disable-next-line
                        console.log(e);
                        this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
                        this.addingUser = false;
                    })
            }
        },
        computed: {
            filteredSelectedUsers() {
                if (this.blankString(this.usersFilterString)) {
                    return this.users.filter(() => true)
                        .sort((a, b) => a['description'] < b['description'] ? -1 : 1);
                } else {
                    return this.users.filter((obj) => `${obj.username} ${obj.description}`.toLowerCase().includes(this.usersFilterString.toLowerCase()))
                        .sort((a, b) => a['description'] < b['description'] ? -1 : 1);
                }
            },
        }

    }
</script>

<style scoped>

</style>
