<template>
  
  <v-layout column fill-height class="scrollable">
<!--    <v-flex shrink class="px-1 pb-1">-->
<!--      <v-btn @click="console.log(chatMessages)">Test</v-btn>-->
<!--    </v-flex>-->
    <v-flex shrink class="px-1 pb-1">
      <combo-bar-filter
          :filter-options="filterOptions"
          :draw-time-out="drawTimeOut"
      />
    </v-flex>
    <v-flex xs12 class="scrollable caption greyType--text" id="scrollTop" ref="comboBody">
      
      <v-scroller
          :items="allItems"
          :labels="dates"
          :items-in-parent="15"
          :flush-count="10"
          ref="sliderScroll"
          key-name="id"
      >
        
        <template #item="{item}">
          
          
          
          <template v-if="item.type === 'chatCompose'">
            <app-combo-compose-message
                :chat-key="`t-${task.taskID}-0`"
                class="scale-in-center"
                ref="composeMessage"
                :task="task"
                @messageSent="loadChatMessages"
            ></app-combo-compose-message>
          </template>
          
          
<!--Draft Notes-->
          <template v-else-if="item.type === 'noteDraft'">
            <v-layout row>
              <v-flex xs10 class="pa-1 py-2" offset-xs2>
                <v-card class="">
                  <task-draft-note
                      :selected-step="selectedStep"
                      :user-info="userInfo"
                      :task="task"
                      :draft="item.payload"
                      :delete-draft-f-n="deleteDraft"
                      @openDraft="createNoteFromDraft($event)"
                  />
                </v-card>
              
              </v-flex>
            </v-layout>
          </template>
<!--Chat Message-->
          <template v-else-if="item.type === 'chatMessage'">
            <div style="width: 100%">
              <app-chat-message
                  :class="{
                    'bubble-left': !item.payload.isAuthor,
                    'bubble-right': item.payload.isAuthor
                  }"
                  :source="item.payload"
                  :selected-message-ids="dummySet"
                  :handle-context-menu="e => messageContext(e, item)"
                  :handle-message-select="dummyHandler"
                  :handle-view-tasks="dummyHandler"
                  :handle-drag-start="dummyHandler"
                  :selected-message-ids-size="0"
                  :show-full-date="true"
                  :allow-attachment-drag="true"
                  :chatAttachmentDragHandler="chatAttachmentDragHandler"
                  :visible-hidden-messages="showDeletedMessages"
              />
            </div>
            
          </template>
          
          
          <!--Draft Mails-->
          <template v-else-if="item.type === 'mailDraft'">
            <v-layout row>
              <v-flex xs10 class="pa-1 py-2" offset-xs2>
                <v-card class="">
                  <task-draft-mail
                      :user-info="userInfo"
                      :selected-step="selectedStep"
                      :task="task"
                      :draft="item.payload"
                      @openDraft="openDraftMailCompose(item.payload)"
                  />
                </v-card>
              
              </v-flex>
            </v-layout>
          </template>
          
          <template v-else>
            <v-layout row align-center class="pa-1 py-2">
              
              <v-flex xs2 v-if="item.mine === true" class="text-xs-center pr-2">
                <v-icon
                    color="secondary"
                    v-if="item.notification"
                >
                  announcement
                </v-icon>
              </v-flex>
              
              <v-flex xs10>
                <v-card>
                  <!--Notes-->
                  <template v-if="item.type === 'note'">
                    <div @contextmenu="show($event, item)">
                      <note-view-container
                          :parent-height="comboSize"
                          :userInfo="userInfo"
                          :task="task"
                          :note="item.payload"
                          @reply="replyToNote($event)"
                      />
                    </div>
                  </template>
                  <!--Mails-->
                  <template v-if="item.type === 'mail'">
                    <div @contextmenu="show($event, item)">
                      <mail-container
                          :mail="item.payload"
                          :task="task"
                          :user-info="userInfo"
                          :dashboard="false"
                          :task-band="false"
                          :task-mail="true"
                          :mail-config="mailConfig"
                          :new-mail-fn="newCompose"
                      />
                    </div>
                  </template>
                  <!--Attachments-->
                  <template v-if="item.type === 'document'">
                    <document-container
                        :document="item.payload"
                        :user-info="userInfo"
                        :show-add-event="true"
                        @addEvent="callAddEvent(item); "
                    />
                  </template>
                  <!--Events-->
                  <template v-if="item.type === 'event'">
                    <event-header :event-i-d="item.payload"/>
                  </template>
                  
                  <div class="text-xs-right caption pr-2 pb-2" v-if="item.type !== 'event'">
                    
                    <v-card
                        v-if="item.stepID !== -1"
                        hover
                        style="display: inline-block; cursor: pointer"
                        color="primary"
                        class="px-1 ma-0 caption white--text"
                        @click="$emit('selectStep', item.stepID)"
                    >
                      {{ flatStepDescriptions[item.stepID] }}
                    </v-card>
                    
                    <v-card
                        v-else
                        hover
                        style="display: inline-block; cursor: pointer"
                        color="primary"
                        class="px-1 ma-0 caption white--text"
                        @click="$emit('selectStep', -1)"
                    >
                      Recycle Bin
                    </v-card>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        
        </template>
        
        <template #label="{label}">
          <v-tooltip left>
            <template v-slot:activator="{on}">
              <div v-on="on" style="border-radius: 100%; width: 10px; height: 10px; background-color: #9d9d9d;">
              </div>
            </template>
            {{ label }}
          </v-tooltip>
        </template>
      
      </v-scroller>
      
      
    
    </v-flex>
    
    <!--        <v-flex shrink class="text-xs-center" v-if="drawTimeOut == null">-->
    <!--            <v-pagination-->
    <!--                    v-if="showPage && totalPages != null && totalPages > 1"-->
    <!--                    v-model="curPage"-->
    <!--                    :length="totalPages"-->
    <!--                    color="primary"-->
    <!--            ></v-pagination>-->
    <!--        </v-flex>-->
    <v-menu
        v-if="!softBlock"
        v-model="showComboMenu"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
    >
      <v-card>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="callAddEvent()">
          <v-icon class="pr-2">event</v-icon>
          Create Calendar Event
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showComboMenu = false">
          <v-icon class="pr-2">close</v-icon>
          Cancel
        </div>
      </v-card>
    </v-menu>
    
    <v-menu
        v-if="showContextMessage"
        v-model="showContextMessage"
        :position-x="contextMessageX"
        :position-y="contextMessageY"
        absolute
        offset-y
    >
      <v-card>
        <template v-if="contextMessage.payload.removed">
          <div v-if="!showDeletedMessages.includes(contextMessage.payload.id)" class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showDeletedMessages.push(contextMessage.payload.id)">
            <v-icon class="pr-2">visibility</v-icon>
            Show message
          </div>
          <div v-else class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showDeletedMessages.splice(showDeletedMessages.indexOf(contextMessage.payload.id), 1)">
            <v-icon class="pr-2">visibility_off</v-icon>
            Hide message
          </div>
        </template>
        <div v-else class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="deleteContextMessage">
          <v-icon class="pr-2">delete</v-icon>
          Delete Message
        </div>
        <div class="c-d-flex c-align-center hover-item pa-2 pr-2" @click="showContextMessage = false">
          <v-icon class="pr-2">close</v-icon>
          Cancel
        </div>
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
import ComboBarFilter from "./Components/ComboBarFilter";
import {mapGetters} from 'vuex';
import {getLongDate, arrPopulated, blankString, arrSplice} from "../../../../codeFunctions";
import TaskDraftMail from "../../components/Mails/TaskDraftMail";
import TaskDraftNote from "../../components/Notes/TaskDraftNote";
import NoteViewContainer from "../../components/Notes/NoteViewContainer";
import DocumentContainer from "../../components/Documents/DocumentContainer";
import {eventBus} from "../../../../main";
import {codeBaseMixin} from "../../../../codeBaseMixin";
import mailContainer from "../../../../components/General/generalMailContainer"
import parse from "date-fns/parse";
import EventHeader from "@/Tasks/TaskView V2/components/Events/EventHeader";
import {calEventOpenEvents} from "@/Calendar View/Event Manager/EventManager";
import VScroller from "@/components/Virtual Scroller/VScroller";
import {ChatMessage, markMessagesAsRead, rtcmRelay} from "@/chat/chatFunctions";
import AppChatMessage from "@/chat/AppChatMessage.vue";
import AppComboComposeMessage from "@/Tasks/TaskView V2/components/Chat Message/AppComboComposeMessage.vue";

export default {
  mixins: [codeBaseMixin],
  components: {
    AppComboComposeMessage,
    AppChatMessage,
    VScroller,
    EventHeader,
    DocumentContainer, NoteViewContainer, TaskDraftNote, TaskDraftMail, ComboBarFilter, mailContainer
  },
  props: {
    task: Object,
    selectedStep: Object,
    mailConfig: {
      required: true,
      type: Object
    },
    chatAttachmentDragHandler: Function
  },
  data() {
    return {
      console,
      showPage: true,
      curPage: 1,
      totalPages: 0,
      pageSize: 15,
      filterStr: null,
      drawTimeOut: null,
      showComboMenu: false,
      x: 0,
      y: 0,
      createEventItem: null,
      comboSize: null,
      chatLoading: false,
      chatMessages: [],
      dummySet: new Set(),
      dummyHandler: () => {},
      contextMessage: null,
      contextMessageX: null,
      contextMessageY: null,
      showContextMessage: false,
      showDeletedMessages: [],
      isDeletingContextMessage: false
    }
  },
  created() {
    calEventOpenEvents.on('calEventRemoved', this.spliceCalEvent)
  },
  beforeDestroy() {
    calEventOpenEvents.off('calEventRemoved', this.spliceCalEvent)
    rtcmRelay.off('chatUpdate', this.handleChatUpdate)
    rtcmRelay.off('messageDeleted', this.handleChatMessageDeleted)
  },
  mounted() {
    // setTimeout(() => {
    //     this.comboSize = this.$refs.comboBody.clientHeight
    // }, 2)
    this.chatStartup()
    rtcmRelay.on('chatUpdate', this.handleChatUpdate)
    rtcmRelay.on('messageDeleted', this.handleChatMessageDeleted)
  },
  
// And there is a place where we one day will delve
// Where there's no more walking on eggshells
// Where ideas are for free, oh it's the place to be
// Your great mind's no longer the minority
  
  watch: {
    filterOptions: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.curPage = 1;
          this.scrollToTheTop();
          if (typeof val.showEvents === 'undefined')
            this.$set(this.filterOptions, "showEvents", true)
        }
        if (this.$refs.sliderScroll)
          this.$refs.sliderScroll.scrollToTop();
      }
    },
    
    "filterOptions.filterStr": {
      immediate: true,
      handler() {
        this.curPage = 1;
        this.scrollToTheTop();
        
        try {
          clearTimeout(this.drawTimeOut);
          this.drawTimeOut = setTimeout(() => {
            this.$nextTick(() => {
              this.drawTimeOut = null
            })
          }, 1000)
        } catch (e) {
          // eslint-disable-next-line
          console.log(e)
        }
      }
    },
    
    comboItems: {
      immediate: true,
      handler(val) {
        if (val === null) {
          this.showPage = false;
          this.totalPages = 0;
          this.curPage = 1
        } else {
          this.totalPages = Math.floor(val.length / this.pageSize) + (val.length % this.pageSize > 0 ? 1 : 0)
        }
      }
    },
    
    curPage(val) {
      if (val > this.totalPages) {
        this.curPage = this.totalPages == 0 ? 1 : this.totalPages
      }
      this.scrollToTheTop();
    },
    
    "windowFocused": {
      handler(val) {
        if (val && this.$store.state.curView === 'app-tasks' && this.onResume) {
          this.onResume()
          this.onResume = null
        }
      }
    },
  },
  
  methods: {
    
    async deleteContextMessage() {
      try {
        this.isDeletingContextMessage = true
        console.log(this.contextMessage)
        this.$newReq('POST', `chat/deleteMessage`, {messageId: this.contextMessage.payload.id})
        this.$snack.info('Message removed')
      } catch (e) {
        console.log(e)
        this.$snack.networkError()
      } finally {
        this.isDeletingContextMessage = false
      }
    },
    
    
    async messageContext(event, message) {
      this.contextMessage = message || null
      event.preventDefault()
      this.showContextMessage = false
      this.contextMessageX = event.clientX
      this.contextMessageY = event.clientY
      this.$nextTick(() => {
        this.showContextMessage = true
      })
    },
    
    async markAsRead() {
      const vm = this
      this.onResume = () => {
        // vm.chat.unread = 0;
        // console.log('cml', this.chatMessages.length)
        markMessagesAsRead(this.taskChatKey)
      }
      if (this.$store.state.chat.windowFocused && this.$store.state.curView === 'app-tasks') {
        this.onResume()
        this.onResume = null
      }
    },
    
    async handleChatUpdate(chat) {
      if (chat?.chatKey === this.taskChatKey)
        if (chat.fkMessageId > this.lastChatMessageId) {
          await this.chatLoadNewMessages()
        }
    },
    
    async handleChatMessageDeleted(payload) {
      if (payload?.chatKey === this.taskChatKey) {
        const deletedMessage = this.chatMessages.find(v => v.id === payload.messageId)
        if (deletedMessage) {
          this.$set(deletedMessage, "removedByContact", payload.detail.removedByContact || null)
          this.$set(deletedMessage, "removedByUser", payload.detail.removedByUser || null)
          this.$set(deletedMessage, "removed", payload.detail.removed || null)
          this.$set(deletedMessage, "removedName", payload.detail.removedName || null)
          this.$set(deletedMessage, "removedAt", payload.detail.removedAt || null)
        }
      }
        // if (chat.fkMessageId > this.lastChatMessageId) {
        //   await this.chatLoadNewMessages()
        // }
    },
    
    spliceCalEvent(calEventID) {
      try {
        arrSplice(this.task.structure.events, (event) => event.id === calEventID)
      } catch (e) {
        console.log(e)
      }
      
    },
    callAddEvent(item) {
      if (item)
        this.createEventItem = item
      
      let config = {
        start: new Date(),
        fkTask: this.task.taskID
      }
      
      switch (this.createEventItem.type) {
        case 'note': {
          config.fkNote = this.createEventItem.payload.noteID
          break;
        }
        case 'mail': {
          config.fkMail = this.createEventItem.payload.mailDBID
          break;
        }
        case 'document': {
          config.fkDoc = this.createEventItem.payload.documentDBID
          break
        }
      }
      
      config.linkPayload = this.createEventItem.payload
      
      this.$createCalendarEvent(config, this.calendarEventCreated)
    },
    calendarEventCreated(event) {
      try {
        this.task.structure.events.push(event)
      } catch (e) {
        this.task.structure.events = [event]
      }
    },
    show(e, item) {
      this.createEventItem = item || null
      e.preventDefault()
      this.showComboMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showComboMenu = true
      })
    },
    newCompose(type, mailSrc, mailBody, draftMessage, draftObject) {
      
      eventBus.$emit('newCompose', {
        type: type,
        mailSrc: mailSrc,
        mailBody: mailBody,
        draftMessage: draftMessage,
        draftObject: draftObject,
      });
      
      this.showCompose = true;
    },
    replyToNote(event) {
      eventBus.$emit('replyToNote', event)
    },
    scrollToTheTop() {
      try {
        var myDiv = document.getElementById('scrollTop');
        myDiv.scrollTop = 0;
      } catch (e) {
        // Do Nothing
      }
    },
    openDraftMailCompose(event) {
      eventBus.$emit('openDraftMailCompose', event)
    },
    createNoteFromDraft(event) {
      eventBus.$emit('createNoteFromDraft', event)
    },
    deleteDraft(draftID) {
      //Delete Draft Here
      this.fetch({method: 'DELETE', url: `/taskCache/note/removeDraftNote/${this.userInfo.entityID}/${draftID}`})
          .then((data) => {
            this.task.structure.draftNotes.forEach((draft, i) => {
              if (draft.draftID === draftID) {
                this.task.structure.draftNotes.splice(i, 1);
              }
            })
          })
          .catch((e) => {
            // eslint-disable-next-line
            console.log(e)
            this.showSnack('Error', 'Network error. Please try again.', 'Close', 'red');
          })
    },
    
    async chatStartup() {
      try {
        this.chatLoading = true
        await this.loadChatMessages()
        this.markAsRead()
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.chatLoading = false
      }
    },
    
    async chatLoadNewMessages() {
      try {
        this.chatLoading = true
        await this.loadChatMessages()
        this.markAsRead()
      } catch (e) {
        console.log(e)
        this.$snack.networkError();
      } finally {
        this.chatLoading = false
      }
    },
    
    async loadChatMessages() {
      let resMessages = await this.$newReq('POST', `chat/channelMessages/after`, {
        chatKey: this.taskChatKey,
        lastMessageId: this.lastChatMessageId
      })
      resMessages = resMessages.map(val => new ChatMessage(val))
      this.chatMessages = resMessages.concat(this.chatMessages)
          .filter((v, i, arr) => arr.findIndex(av => av.id === v.id) === i)
    }
    
    
  },
  computed: {
    ...mapGetters("userInfo", ["softBlock"]),
    
    windowFocused() {
      return this.$store.state.chat.windowFocused
    },
    
    taskChatKey() {
      return `t-${this.task.taskID}-0`
    },
    
    lastChatMessageId() {
      return this.chatMessages.reduce((maxId, message) => Math.max(maxId, message.id), 0)
    },
    
    allItems() {
      
      if (this.$store.state.chat.comboBarShowCompose) {
        return [{type: "chatCompose", id: -20}, ...this.draftItems, ...this.comboItems]
            .map((obj, i) => {
              obj.id = obj.id === -20 ? -20 : i
              return obj
            })
      } else {
        return [...this.draftItems, ...this.comboItems]
            .map((obj, i) => {
              obj.id = i
              return obj
            })
      }
      
      
      
    },
    
    dates() {
      const list = [];
      let lastAdded = null
      for (let k = 0; k < this.allItems.length; ++k) {
        if (list.length === 0 || lastAdded !== this.allItems[k].dateStr) {
          lastAdded = this.allItems[k].dateStr
          list.push(this.allItems[k].dateStr)
        } else {
          list.push(null)
        }
      }
      return list
    },
    
    flatStepDescriptions() {
      try {
        return this.task.structure.steps.reduce((obj, step) => {
          obj[step.stepID] = step.description;
          return obj;
        }, {})
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
        return null
      }
    },
    
    ...mapGetters('userInfo', ['userInfo']),
    hiddenSteps() {
      return this.task.structure.steps
          .filter(step => step.shown === 0)
          .map(step => {
            return step.stepID
          })
    },
    recycleSteps() {
      return this.task.structure.steps.filter(step => step.recycleStep === 1).map(step => {
        return step.stepID
      })
    },
    draftItems() {
      let drafts = [];
      // Notes
      if (this.filterOptions.showNotes && arrPopulated(this.task.structure.draftNotes)) {
        drafts = drafts.concat(
            this.task.structure.draftNotes
                .filter(draft => draft.userID === this.userInfo.userID && (!this.filterOptions.stepOnly ? true : this.selectedStep.stepID === draft.stepID))
                .map(note => {
                  try {
                    return {
                      type: 'noteDraft',
                      date: new Date(note.created),
                      dateStr: getLongDate(new Date(note.created)),
                      stepID: note.stepID,
                      payload: note,
                      mine: note.userID === this.userInfo.userID
                    }
                  } catch (e) {
                    console.log(e)
                    return null
                  }
                })
        )
      }
      // Mails
      if (this.filterOptions.showMails && arrPopulated(this.task.structure.draftMails)) {
        drafts = drafts.concat(
            this.task.structure.draftMails
                .filter(draft => draft.fk_userID === this.userInfo.userID && (!this.filterOptions.stepOnly ? true : this.selectedStep.stepID === draft.stepID))
                .map(mail => {
                  try {
                    return {
                      type: 'mailDraft',
                      date: new Date(mail.created),
                      dateStr: getLongDate(new Date(mail.created)),
                      stepID: mail.stepID,
                      payload: mail,
                      mine: mail.userID === this.userInfo.userID
                    }
                  } catch (e) {
                    console.log(e)
                    return null
                  }
                })
        )
      }
      return this.filterOptions.orderAscending
          ? drafts.sort((a, b) => a.date < b.date ? -1 : 1)
          : drafts.sort((a, b) => a.date < b.date ? 1 : -1)
    },
    comboItems() {
      
      let items = [];
      
      // Chat Messages
      if (this.filterOptions.showChat && this.chatItems != null) {
        items = items.concat(
            this.chatItems.map(message => {
              try {
                
                let notification = false;
                
                // try {
                //   notification = JSON.parse(note.noteViewFlags).reduce((notif, vf) => {
                //     return notif ? notif : vf.viewFlag === 0 && vf.userID === this.userInfo.userID
                //   }, false);
                // } catch (e) {
                //   notification = false
                // }
                
                return {
                  type: 'chatMessage',
                  date: new Date(message.createdAt),
                  dateStr: getLongDate(new Date(message.createdAt)),
                  stepID: null,
                  payload: message,
                  mine: message.isAuthor,
                  notification: notification
                }
              } catch (e) {
                console.log(e)
                return null
              }
            })
        )
      }
      
      // Notes
      if (this.filterOptions.showNotes && this.noteItems != null) {
        items = items.concat(
            this.noteItems.map(note => {
              try {
                
                let notification = false;
                
                try {
                  notification = JSON.parse(note.noteViewFlags).reduce((notif, vf) => {
                    return notif ? notif : vf.viewFlag === 0 && vf.userID === this.userInfo.userID
                  }, false);
                } catch (e) {
                  notification = false
                }
                
                return {
                  type: 'note',
                  date: new Date(note.created),
                  dateStr: getLongDate(new Date(note.created)),
                  stepID: note.stepID,
                  payload: note,
                  mine: note.authorID === this.userInfo.userID,
                  notification: notification
                }
              } catch (e) {
                console.log(e)
                return null
              }
            })
        )
      }
      // Mails
      if (this.filterOptions.showMails && this.mailItems != null) {
        items = items.concat(
            this.mailItems
                .filter(mail => !!mail.mailFile)
                .map(mail => {
                  try {
                    let notification = mail.autoLinkMail == 1 && mail.autoLinkNotification === 1 && mail.linkUser === this.userInfo.userID;
                    return {
                      type: 'mail',
                      date: parse(mail.created),//new Date(mail.created),
                      dateStr: getLongDate(parse(mail.created)),
                      stepID: mail.stepID,
                      payload: mail,
                      mine: mail.linkUser === this.userInfo.userID,
                      notification: notification
                    }
                  } catch (e) {
                    console.log(e)
                    return null
                  }
                })
        )
      }
      // Documents
      if (this.filterOptions.showDocuments && this.documentItems != null) {
        items = items.concat(
            this.documentItems.map(doc => {
              try {
                return {
                  type: 'document',
                  date: new Date(doc.linkDate),
                  dateStr: getLongDate(new Date(doc.linkDate)),
                  stepID: doc.stepID,
                  payload: doc,
                  mine: doc.linkUser === this.userInfo.userID,
                  notification: false
                }
              } catch (e) {
                console.log(e)
                return null
              }
            })
        )
      }
      // if  (this.filterOptions.showDocuments && this.events != null) {
      if (this.filterOptions.showEvents && this.eventItems != null) {
        items = items.concat(
            this.eventItems.filter(event => event.hasAccess).map(event => {
              try {
                return {
                  type: 'event',
                  date: new Date(event.created),
                  dateStr: getLongDate(new Date(event.created)),
                  stepID: 0,
                  payload: event,
                  mine: event.fkOwner === this.userInfo.userID,
                  notification: false
                }
              } catch (e) {
                console.log(e)
                return null
              }
            })
        )
      }
      
      return (
          this.filterOptions.orderAscending
              ? items.sort((a, b) => a.date < b.date ? -1 : 1)
              : items.sort((a, b) => a.date < b.date ? 1 : -1)
      )
          .reduce((list, item) => {
            if (!blankString(this.filterOptions.filterStr)) {
              return JSON.stringify(item.payload).toLowerCase().includes(this.filterOptions.filterStr.toLowerCase())
                  ? list.concat([item])
                  : list;
            } else {
              return list.concat([item]);
            }
            
          }, [])
          .reduce((list, item) => {
            if (this.selectedStep.stepID === -1) {
              return item.stepID === -1 ? list.concat([item]) : list
            } else {
              return item.stepID !== -1 ? list.concat([item]) : list
            }
          }, [])
          .filter(item => this.filterOptions.userOnly ? item.mine : true)
    },
    noteItems() {
      try {
        return this.task.structure.notes
            .filter(note => !this.hiddenSteps.includes(note.stepID) && (!this.filterOptions.stepOnly ? true : this.selectedStep.stepID === note.stepID))
      } catch (e) {
        // eslint-disable-next-line
        // console.log(e)
        return null
      }
    },
    mailItems() {
      try {
        return this.task.structure.mails
            .filter(mail => !this.hiddenSteps.includes(mail.stepID) && (!this.filterOptions.stepOnly ? true : this.selectedStep.stepID === mail.stepID))
      } catch (e) {
        // eslint-disable-next-line
        // console.log(e)
        return null
      }
    },
    documentItems() {
      try {
        return this.task.structure.documents
            .filter(doc => !this.hiddenSteps.includes(doc.stepID) && (!this.filterOptions.stepOnly ? true : this.selectedStep.stepID === doc.stepID))
      } catch (e) {
        // eslint-disable-next-line
        // console.log(e)
        return null
      }
    },
    eventItems() {
      return !this.filterOptions.stepOnly ? this.task.structure.events : null
    },
    chatItems() {
      try {
        return this.chatMessages.filter(message => !this.filterOptions.stepOnly)
      } catch (e) {
        // eslint-disable-next-line
        // console.log(e)
        return null
      }
    },
    filterOptions: {
      get() {
        return this.userInfo.splitTaskViewPreset
      },
      set(val) {
        this.userInfo.splitTaskViewPreset = val
      }
    }
  }
}
</script>

<style scoped>
div.v-card {
  border-radius: 5px;
}
</style>
